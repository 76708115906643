import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { questsModalHide, collectToastError, collectToastSuccess, collectUserData } from "../../redux/counterSlice";
import { ReactSession } from 'react-client-session';
import CreateMatchManager from "/components/create-match-manager/create-match-manager.jsx";
import Image from "next/image";
import ProgressBar from "/components/progress-bar/progress-bar.jsx";
import { calculateProgressValue } from "../../utils/utils";
import EventCollectModal from "../../components/modal/eventCollectModal";

const QuestsModal = () => {
  const { userData } = useSelector((state) => state.counter);
  const { questsModal } = useSelector((state) => state.counter);
  const dispatch = useDispatch();
  const [showMatchPopup, setshowMatchPopup] = useState(false);
  const [selected, setSelected] = useState(null);
  const [choosenReward, setChoosenReward] = useState({});
  const [showSpinner, setShowSpinner] = useState(false);
  const [resultData, setResultData] = useState(null);

  useEffect(() => {
    questsModal && userData?.quests[0]?.pk && setSelected(userData?.quests[0]?.pk);
  }, [questsModal]);

  const handleAccordion = (id) => {
    if (selected === id) {
      setSelected(null);
    } else {
      setSelected(id);
    }
  };
  const showErrorToast = (message) => {
    dispatch(collectToastError(message.toString()));
  };

  const handleClose = () => {
    setChoosenReward({});
    setSelected(null);
    dispatch(questsModalHide());
  };

  const collect = (questId) => {
    setShowSpinner(true);
    const requestBody = { questId: questId, cardId: choosenReward.pk };
    let formBody = [];
    for (var property in requestBody) {
      var encodedKey = encodeURIComponent(property);
      var encodedValue = encodeURIComponent(requestBody[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");
    fetch(`${process.env.NEXT_PUBLIC_API_ROUTE}/api/users/claimQuest/`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
        "Authorization": `Token ${ReactSession.get("token")}`,
      },
      body: formBody
    })
      .then(res => res.json())
      .then(response => {
        if (response.success) {
          setResultData({ card: response.data });
          var tempUser = ReactSession.get("user");
          for (let index = 0; index < tempUser.quests.length; index++) {
            if (tempUser.quests[index].pk == questId) {
              tempUser.quests[index].claimed = true;
              tempUser.quests[index].rewardCard = { image: response.data?.image, pk: response.data?.pk };
            }
          }
          ReactSession.set("user", tempUser);
          dispatch(collectUserData(tempUser));
          setShowSpinner(false);
        }
        else {
          setShowSpinner(false);
          dispatch(collectToastError("There was an error while requesting data!"));
        }
      });
  }

  return (
    <>
      <div className={questsModal ? "modal fade show flex flex-col items-center justify-center backdrop-blur" : "modal fade"}>
        <div className="inset-0 absolute !cursor-arrow"
          onClick={() => handleClose()}
        ></div>
        <div className="rounded-xl flex flex-col items-stretch justigy-stretch border border-accent-darker m-auto max-h-[90%] md:max-h-[96vh] w-full max-w-[91vw] md:max-w-[640px] relative overflow-hidden">
          {/* <!-- HEADER --> */}
          <div className="shrink px-4 py-1.5 md:px-6 md:py-3 relative bg-dark border-b border-accent-darker flex items-center justify-between">
            <h2 className="font-display text-lg py-1 uppercase">
              Challenge
            </h2>
            <button
              type="button"
              className="btn-close"
              onClick={() => handleClose()}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                width="24"
                height="24"
                className="h-6 w-6 fill-white"
              >
                <path fill="none" d="M0 0h24v24H0z"></path>
                <path d="M12 10.586l4.95-4.95 1.414 1.414-4.95 4.95 4.95 4.95-1.414 1.414-4.95-4.95-4.95 4.95-1.414-1.414 4.95-4.95-4.95-4.95L7.05 5.636z"></path>
              </svg>
            </button>
          </div>
          {/* <!-- BANNER --> */}
          <div className="shrink border-b border-accent-darker">
            <Image
              width={600}
              height={200}
              src="https://chronogram.s3.amazonaws.com/images/quest_banner.webp"
              alt="Challenge Banner"
              className="h-full w-full object-cover"
            />
          </div>
          {/* <!-- BODY --> */}
          <div className="grow flex flex-col items-stretch justify-start bg-darkest !h-full overflow-y-auto">
            <div>
              {userData?.quests && userData.quests.map((item) => {
                const questProg = calculateProgressValue(item.counter, item.gameQuest.amount);
                const canCollect = item.completed && !item.claimed;
                return (
                  <div className="group accordion-item bg-darker hover:bg-dark overflow-hidden transition-all"
                    key={item.pk}>
                    <div className={`relative cursor-pointer accordion-button font-body flex flex-col gap-2 w-full items-center justify-between px-4 md:px-5 pt-5 pb-2 text-white 
                      ${selected === item.pk
                        ? ""
                        : "collapsed"}`}
                      onClick={() => handleAccordion(item.pk)}>
                      <div className="flex flex-col gap-y-5 w-full">
                        <div className="flex gap-4 items-center justify-stretch leading-5">
                          {item.completed ?
                            (<svg className="icon w-5 h-5 shrink" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                              <path className="fill-green" d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM369 209L241 337c-9.4 9.4-24.6 9.4-33.9 0l-64-64c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l47 47L335 175c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9z" />
                            </svg>)
                            : null
                          }
                          <span className="inline-block text-md font-semibold grow w-full">
                            {item.gameQuest.name}
                          </span>
                          {!item.completed ? (<>
                            {(
                              <button
                                onClick={() => setshowMatchPopup(true)}
                                className="shrink px-4 py-1.5 bg-accent shadow-accent-volume hover:bg-accent-dark rounded-full text-center text-white text-sm font-semibold transition-all"
                              >
                                Go&nbsp;There&nbsp;{`>`}
                              </button>
                            )}
                          </>) : null}
                        </div>
                        <ProgressBar className={"h-7 w-full sm:h-6"} value={questProg} label={`${questProg}%`} />
                      </div>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        width="24"
                        height="24"
                        className="accordion-arrow h-6 w-6 transition-transform fill-white"
                      >
                        <path fill="none" d="M0 0h24v24H0z"></path>
                        <path d="M12 13.172l4.95-4.95 1.414 1.414L12 16 5.636 9.636 7.05 8.222z"></path>
                      </svg>
                    </div>
                    <div className={`accordion-collapse collapse ${selected === item.pk
                      ? 'show'
                      : ''}`}>
                      <div className="accordion-body text-sm text-jacarta-300 bg-darkest border-accent-darker border-t p-4 transition-all flex flex-col gap-y-2 leading-5">
                        <div className=""
                          dangerouslySetInnerHTML={{ __html: item.gameQuest.description }}
                        ></div>
                      </div>
                    </div>
                    {item.gameQuest?.rewards && (
                      <div className="bg-darker px-4 pt-5 pb-6 flex flex-col gap-y-6 border-accent-darker border-t transition-all">
                        <div className="text-sm leading-5">
                          {!item.claimed ?
                            <> <span className="font-bold">Rewards: </span><br />You can choose one of the following limited edition variants!</>
                            :
                            <><span className="font-bold">Rewards: </span><br />You{"'"}ve already claimed this reward!</>
                          }
                        </div>
                        <div className={`flex ${item.claimed ? "flex-row overflow-x-scroll md:overflow-x-visible" : "flex-col px-8"} md:!flex-row gap-5 md:gap-4  md:px-0 items-center justify-stretch`}>
                          {!item.claimed ?
                            item.gameQuest.rewards.map((reward) => {
                              return (
                                <div key={reward.pk} className="flex flex-col gap-y-2 items-center pb-3 bg-[#00000025] rounded-lg">
                                  <div className={`${reward.pk === choosenReward?.pk ? "quests-reward-card" : "saturate-50 opacity-75"} shrink cursor-pointer transition-all`}
                                    onClick={() => {
                                      setChoosenReward(reward);
                                    }}>
                                    <Image
                                      src={reward.image}
                                      width={454}
                                      height={635}
                                      alt={reward.pk}
                                    />
                                  </div>
                                  <a className="px-4 underline py-1.5 text-center text-accent-light hover:text-white text-sm font-semibold transition-all"
                                    href={`/cards/${reward.setId}/${reward.pk}?versions=0`}
                                    target="_blank">
                                    View&nbsp;Card&nbsp;&nbsp;{'>'}
                                  </a>
                                </div>
                              );
                            })
                            :
                            item.gameQuest.rewards.map((reward) => {
                              return (
                                <div key={reward.pk} className="flex flex-col gap-y-2 items-center pb-3 bg-[#00000025] rounded-lg">
                                  <div className={`${reward.pk === item.rewardCard?.pk ? "quests-reward-card" : "saturate-0 opacity-50"} shrink transition-all pointer-events-none`}>
                                    <Image className=""
                                      src={reward.image}
                                      width={454}
                                      height={635}
                                    />
                                  </div>
                                  <a className="px-4 underline py-1.5 text-center text-accent-light hover:text-white text-sm font-semibold transition-all"
                                    href={`/cards/${reward.setId}/${reward.pk}?versions=0`}
                                    target="_blank">
                                    View&nbsp;Card&nbsp;&nbsp;{'>'}
                                  </a>
                                </div>
                              );
                            })
                          }

                        </div>
                        {!item.claimed ?
                          <button
                            onClick={() => {
                              choosenReward && choosenReward.pk && canCollect ?
                                collect(item.pk)
                                :
                                (canCollect ?
                                  showErrorToast("You must select one of the rewards to claim!")
                                  :
                                  showErrorToast("You must first complete all of the tasks on the list!"));
                            }}
                            type="button"
                            className="bg-accent shadow-accent-volume hover:bg-accent-dark rounded-full py-2 md:py-3 px-6 md:px-12 md:mx-auto text-center font-semibold text-white transition-all"
                          >
                            Claim Reward
                          </button>
                          : null}
                      </div>
                    )}

                  </div>
                );
              })}
            </div>
          </div>
          {showSpinner ?
            <div className="absolute inset-0 bg-[#000000da] flex items-center justify-center">
              <img width={128} height={128} className="m-auto w-[128px] h-[128px]" src="/svg/spinner.svg" alt="Loading Spinner" />
            </div>
            : null}
        </div>
      </div>
      {/* RESULT POPUP */}
      {resultData ?
        <EventCollectModal resultData={resultData}
          onCloseAction={() => {
            dispatch(collectToastSuccess("Congratulations! your challenge reward was claimed."));
            setResultData(null);
            handleClose();
          }}
          title="Your Challenge Rewards" />
        : null}
      {/* END RESULT POPUP */}

      {/* START MATCH POPUP */}
      {showMatchPopup ?
        <CreateMatchManager cancelAction={() => setshowMatchPopup(false)} /> :
        null
      }
      {/* END START MATCH POPUP */}
    </>
  );
};

export default QuestsModal;
