import Image from "next/image";
import Link from "next/link";
import DarkMode from "../mode/DarkMode";
import Events from "../events";
import Logo from "./../../public/images/logo/Logo_F_C.png";
import WhiteLogo from "./../../public/images/logo/Logo_F_D.png";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExplosion, faBomb, faCoins, faScroll, faCalendarDays, faShop } from '@fortawesome/free-solid-svg-icons';
import { v4 as uuidv4 } from "uuid";
import { useRouter } from "next/router";
import { useTheme } from "next-themes";
import {
  isChildrenPageActive,
  isParentPageActive,
} from "../../utils/daynamicNavigation";
import { maintenance } from "/utils/maintenance";

import { Autoplay, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/autoplay";

import { useEffect, useState, useRef } from "react";
import { useSession, signIn, signOut } from 'next-auth/react';
// import WalletButton from "../wallet-btn/WalletButton";
import { ReactSession } from 'react-client-session';
import { useSelector, useDispatch } from "react-redux";
import { loginModalShow, registerModalShow, collectUserData, collectToastError, connectModalShow, continueGuestModalShow } from "../../redux/counterSlice";
// import TiktokPixel from 'tiktok-pixel';

import CreateMatchManager from "/components/create-match-manager/create-match-manager.jsx";

export default function Header() {
  const { theme, setTheme } = useTheme();
  const { userData } = useSelector((state) => state.counter);
  const dispatch = useDispatch();
  const { data, status } = useSession();
  const route = useRouter();
  const [isEventActive, setIsEventActive] = useState(false);

  ReactSession.setStoreType("localStorage");

  useEffect(() => {
    document.body.scrollTo(0, 0)
  }, [route.asPath]);

  useEffect(() => {
    if (theme === "light") setTheme("dark");
  }, []);

  useEffect(() => {
    if (data && data?.accessToken && data?.accessToken?.account && data?.accessToken?.account?.id_token && (!ReactSession.get("user") || (ReactSession.get("user") && ReactSession.get("user")?.isGuest))) {
      const googleToken = data?.accessToken?.account?.id_token;
      const requestBody = {
        id_token: googleToken
      };
      const utm_source = ReactSession.get('utm_source')
      const utm_medium = ReactSession.get('utm_medium')
      const utm_campaign = ReactSession.get('utm_campaign')
      const utm_term = ReactSession.get('utm_term')
      const utm_content = ReactSession.get('utm_content')
      if (utm_source) {
        requestBody['utm_source'] = utm_source;
      }
      if (utm_medium) {
        requestBody['utm_medium'] = utm_medium;
      }
      if (utm_campaign) {
        requestBody['utm_campaign'] = utm_campaign;
      }
      if (utm_term) {
        requestBody['utm_term'] = utm_term;
      }
      if (utm_content) {
        requestBody['utm_content'] = utm_content;
      }

      let formBody = [];
      for (var property in requestBody) {
        var encodedKey = encodeURIComponent(property);
        var encodedValue = encodeURIComponent(requestBody[property]);
        formBody.push(encodedKey + "=" + encodedValue);
      }
      formBody = formBody.join("&");
      const token = ReactSession.get('token')
      let googleUrl = `${process.env.NEXT_PUBLIC_API_ROUTE}/api/users/loginWithGoogle/`
      if (token) {
        googleUrl = `${process.env.NEXT_PUBLIC_API_ROUTE}/api/users/registerGuestWithGoogle/`
      }
      fetch(`${process.env.NEXT_PUBLIC_API_ROUTE}/api/users/loginWithGoogle/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
        },
        body: formBody
      })
        .then(res => res.json())
        .then(response => {
          if (response.success) {
            ReactSession.set("user", response.data.user);
            ReactSession.set("token", response.data.token);
            dispatch(collectUserData(response.data.user));
            signOut();
            if (response.data.user?.register) {
              window.dataLayer = window.dataLayer || [];
              window.dataLayer.push({
                event: 'SignUp'
              })
              // TiktokPixel.track('CompleteRegistration', {});
            }
            if (token) {
              setTimeout(() => {
                window.location.reload()
              }, 1000);
            }
          }
          return true
        });
    }
  }, [data]);


  // const [user, setUser] = useState(null);
  const [token, setToken] = useState(null);
  const [announcements, setAnnouncements] = useState([]);

  // useEffect(() => {
  //   if (announcements.length) {
  //     setTimeout(() => {
  //       const tempAnnouncements = [...announcements].slice(1)
  //       tempAnnouncements.push(announcements[0])
  //       setAnnouncements(tempAnnouncements)
  //     }, 5000);
  //   }
  // }, [announcements]);

  useEffect(() => {
    fetch(`${process.env.NEXT_PUBLIC_API_ROUTE}/api/users/getAnnouncements/`, {
      method: 'GET',
    })
      .then(async response => {
        const data = await response.json()
        maintenance(data)
        if (data.success) {
          setAnnouncements(data.data)
        }
      })
      .catch(error => console.error('There was an error!', error))
  }, []);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      if (ReactSession.get("user") && !userData) {
        dispatch(collectUserData(ReactSession.get("user")));
      }
      if (ReactSession.get("token")) {
        setToken(ReactSession.get("token"))
      }
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      const utm_source = urlParams.get('utm_source')
      const utm_medium = urlParams.get('utm_medium')
      const utm_campaign = urlParams.get('utm_campaign')
      const utm_term = urlParams.get('utm_term')
      const utm_content = urlParams.get('utm_content')
      if (utm_source) {
        ReactSession.set('utm_source', utm_source)
      }
      if (utm_medium) {
        ReactSession.set('utm_medium', utm_medium)
      }
      if (utm_campaign) {
        ReactSession.set('utm_campaign', utm_campaign)
      }
      if (utm_term) {
        ReactSession.set('utm_term', utm_term)
      }
      if (utm_content) {
        ReactSession.set('utm_content', utm_content)
      }
    }
  }, []);

  // useEffect(() => {
  //   setTimeout(() => {
  //     var audio = new Audio('https://chronogram.s3.amazonaws.com/audio/Chinese.mp3');
  //     audio.play();
  //   }, 5000);
  // }, []);

  const [toggle, setToggle] = useState(false);
  const [isCollapse, setCollapse] = useState(null);
  const [isScroll, setScroll] = useState(false);

  // sticky menu
  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.pageYOffset > 4) {
        setScroll(true);
      } else {
        setScroll(false);
      }
    });
  }, []);

  // window resize
  useEffect(() => {
    window.addEventListener("resize", () => {
      if (window.innerWidth >= 1024 && toggle) {
        setToggle(false);
      }
    });
  });

  const handleSignOut = () => {
    ReactSession.set("pantheons", null)
    ReactSession.set("user", null)
    ReactSession.set("token", null)
    dispatch(collectUserData(null));
    window.location.replace("/");
  };

  // const createMatch = () => {
  //   fetch(`${process.env.NEXT_PUBLIC_API_ROUTE}/api/game/createMatch/`, {
  //     method: 'POST',
  //     headers: {
  //       'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
  //       'Authorization': `Token ${token}`,
  //     },
  //   })
  //     .then(res => res.json())
  //     .then(response => {
  //       if (response.success) {
  //         ReactSession.set("match", response.data)
  //         setTimeout(() => {
  //           window.location.replace("/match");
  //         }, 100);
  //       }
  //     });
  // };

  const marketToast = () => {
    dispatch(collectToastError("Reach Level 10 to unlock the Market"))
  };
  /* -------------------------------------------------------------------------- */
  /*                            daynamic navigations                            */
  /* -------------------------------------------------------------------------- */
  const play = {
    id: 1,
    name: "Play",
    pages: [
      {
        id: uuidv4(),
        name: "Ranked",
        path: "",
        action: (e) => {
          e && e.preventDefault();
          setshowMatchPopup(true);
        },
      },
      {
        id: uuidv4(),
        name: "Dig",
        path: "/dig",
      },
      {
        id: uuidv4(),
        name: "AFK Rewards",
        path: "/afk-rewards",
      },
    ],
  };

  const cards = {
    id: 2,
    name: "Cards",
    pages: [
      {
        id: uuidv4(),
        name: "Open Packs",
        path: "/packs",
      },
      {
        id: uuidv4(),
        name: "My Cards",
        path: "/my-cards",
      },
      {
        id: uuidv4(),
        name: "My Decks",
        path: "/decks",
      },
      {
        id: uuidv4(),
        name: "Checklist",
        path: "/cards",
      },
    ],
  };

  const community = {
    id: 3,
    name: "Community",
    pages: [
      {
        id: uuidv4(),
        name: "Activity",
        path: "/activity",
      },
      {
        id: uuidv4(),
        name: "Rankings",
        path: "/rankings",
      },
      {
        id: uuidv4(),
        name: "FAQ",
        path: "/faq",
      },
    ],
  };

  const mobileCollapse = (id) => {
    if (isCollapse === id) {
      return setCollapse(null);
    }
    setCollapse(id);
  };

  const [showMatchPopup, setshowMatchPopup] = useState(false);

  useEffect(() => {
    setIsEventActive(
      userData?.quests?.every((quest) => !quest.claimed) ||
      userData?.event?.imageUrl ||
      !userData?.collectedOnboarding
    );
  }, [userData])

  return <>
    {/* main desktop menu sart*/}
    <header
      className={`fixed top-0 z-20 w-full backdrop-blur-dark transition-colors ${isScroll ? "backdrop-blur-dark-sticky" : ""
        }`}
    >
      {announcements.length && userData && userData?.pk ? (
        <div className="announcement">
          <div className="">
            <Swiper
              modules={[Autoplay]}
              pagination={{ clickable: false }}
              slidesPerView={1}
              autoplay={{
                delay: 5000,
                pauseOnMouseEnter: true,
                disableOnInteraction: false
              }}
              loop
              className='swiper-container'
            >
              {announcements.map((announcement, index) => (
                <SwiperSlide key={index}>
                  <div className="text-center">
                    <Link href={announcement.url} target={announcement.blank ? "_blank" : "_self"}>
                      {announcement.text}
                    </Link>
                  </div>
                </SwiperSlide>
              ))}
              {userData && userData?.isGuest ? (
                <SwiperSlide key="safe">
                  <div className="text-center">
                    <Link href="javascript:void(0)" target="_self" onClick={() => dispatch(connectModalShow())}>
                      Safe Your Progress! Register your account
                    </Link>
                  </div>
                </SwiperSlide>
              ) : null}
            </Swiper>
          </div>
        </div>
      ) : null}
      <div className="flex items-center px-3 py-3 xl:px-24">
        <Link className="shrink-0" href={userData && userData?.pk ? `/play` : "/"} >
          <div className="dark:hidden mt-1 normalLogo">
            <Image
              src={Logo}
              width={200}
              height={54}
              alt="Chronogram"
              className="h-auto"
            />
          </div>
          <div className="hidden dark:block mt-1 normalLogo">
            <Image
              src={WhiteLogo}
              width={200}
              height={54}
              alt="Chronogram"
            />
          </div>
          <div className="mt-1 mobileLogo">
            <Image
              src="/images/Dark-Logo-Icon.png"
              width={200}
              height={54}
              alt="Chronogram"
              className="h-auto"
            />
          </div>

        </Link>
        {/* End  logo */}

        <div className="js-mobile-menu dark:bg-jacarta-800 invisible fixed inset-0 z-10 ml-auto items-center bg-white opacity-0 lg:visible lg:relative lg:inset-auto lg:flex lg:bg-transparent lg:opacity-100 dark:lg:bg-transparent w-full">
          <nav className="navbar w-full mt-24  lg:mt-0">
            <ul className="flex flex-col justify-center lg:flex-row">
              {/* home */}
              {userData && userData?.pk ?
                <li className="js-nav-dropdown group relative flex">
                  <button onClick={() => userData && userData?.pk ? window.location.replace("/play") : dispatch(loginModalShow())}
                    className="bg-accent shadow-accent-volume hover:bg-accent-dark w-full rounded-full py-2 px-6 my-auto text-center font-semibold text-white transition-all flex items-center justify-between text-base dark:text-white">
                    <span>
                      Play
                    </span>
                    <i className="lg:hidden">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        width={24}
                        height={24}
                        className="h-4 w-4 dark:fill-white"
                      >
                        <path fill="none" d="M0 0h24v24H0z" />
                        <path d="M12 13.172l4.95-4.95 1.414 1.414L12 16 5.636 9.636 7.05 8.222z" />
                      </svg>
                    </i>
                  </button>
                  {userData && userData?.pk ?
                    <ul
                      className="dropdown-menu dark:bg-darker border-1 border-accent left-0 top-[85%] z-10 hidden min-w-[200px] gap-x-4 whitespace-nowrap rounded-xl bg-white transition-all will-change-transform group-hover:visible group-hover:opacity-100 lg:invisible lg:absolute lg:grid lg:translate-y-4 lg:py-4 lg:px-2 lg:opacity-0 lg:shadow-2xl lg:group-hover:translate-y-2 relative"
                      aria-labelledby="navDropdown-4"
                    >
                      {play?.pages?.map((page) =>
                      (<li key={page.id}>
                        <Link
                          onClick={page.action ?? null}
                          href={page.action ? "#" : page.path}
                          className="dark:hover:bg-dark  hover:text-accent focus:text-accent hover:bg-jacarta-50 flex items-center rounded-md px-5 py-2 transition-colors justify-between "
                        >
                          <span
                            className={`font-display ${isChildrenPageActive(page.path, route.asPath)
                              ? "text-accent dark:text-accent"
                              : "text-jacarta-700"
                              } text-sm dark:text-white`}
                          >
                            {page.name}
                          </span>
                          {page.condition ? (
                            <span className="rounded bg-green py-1 px-2 text-tiny font-bold uppercase leading-none text-white ml-4">
                              new
                            </span>
                          ) : undefined}
                        </Link>
                      </li>)
                      )}
                    </ul>
                    : null}
                </li>
                : null}
              {/* create */}
              <li className="group">
                <Link href={userData && userData?.pk ? `/user/${userData.pk}` : "/"} >

                  <button className="text-jacarta-700 font-display hover:text-accent focus:text-accent dark:hover:text-accent dark:focus:text-accent flex items-center justify-between py-3.5 text-base dark:text-white lg:px-5">
                    <span
                      className={
                        isChildrenPageActive(route.asPath, "/")
                          ? "text-accent dark:text-accent"
                          : ""
                      }
                    >
                      {userData && userData?.pk ? "Profile" : "Home"}
                    </span>
                  </button>

                </Link>
              </li>

              {/* cards */}
              {userData && token ? (
                <li className="js-nav-dropdown group relative">
                  <button className="dropdown-toggle text-jacarta-700 font-display hover:text-accent focus:text-accent dark:hover:text-accent dark:focus:text-accent flex items-center justify-between p-3.5 text-base dark:text-white lg:px-5 w-full">
                    <span
                      className={
                        isParentPageActive(cards.pages, route.asPath)
                          ? "text-accent  dark:text-accent"
                          : ""
                      }
                    >
                      Cards
                    </span>
                    <i className="lg:hidden">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        width={24}
                        height={24}
                        className="h-4 w-4 dark:fill-white"
                      >
                        <path fill="none" d="M0 0h24v24H0z" />
                        <path d="M12 13.172l4.95-4.95 1.414 1.414L12 16 5.636 9.636 7.05 8.222z" />
                      </svg>
                    </i>
                  </button>
                  <ul
                    className="dropdown-menu dark:bg-darker border-1 border-accent left-0 top-[85%] z-10 hidden min-w-[200px] gap-x-4 whitespace-nowrap rounded-xl bg-white transition-all will-change-transform group-hover:visible group-hover:opacity-100 lg:invisible lg:absolute lg:grid lg:translate-y-4 lg:py-4 lg:px-2 lg:opacity-0 lg:shadow-2xl lg:group-hover:translate-y-2 relative"
                    aria-labelledby="navDropdown-4"
                  >
                    {cards?.pages?.map((page) => (
                      <li key={page.id}>
                        <Link
                          href={page.path}
                          className="dark:hover:bg-dark  hover:text-accent focus:text-accent hover:bg-jacarta-50 flex items-center rounded-md px-5 py-2 transition-colors justify-between "
                        >

                          <span
                            className={`font-display ${isChildrenPageActive(page.path, route.asPath)
                              ? "text-accent dark:text-accent"
                              : "text-jacarta-700"
                              } text-sm dark:text-white`}
                          >
                            {page.name}
                          </span>
                          {page.condition ? (
                            <span className="rounded bg-green py-1 px-2 text-tiny font-bold uppercase leading-none text-white ml-4">
                              new
                            </span>
                          ) : undefined}

                        </Link>
                      </li>
                    ))}
                  </ul>
                </li>
              ) : (
                <li className="group">
                  <Link href="/cards" >

                    <button className="text-jacarta-700 font-display hover:text-accent focus:text-accent dark:hover:text-accent dark:focus:text-accent flex items-center justify-between py-3.5 text-base dark:text-white lg:px-5">
                      <span
                        className={
                          isChildrenPageActive(route.asPath, "/cards")
                            ? "text-accent dark:text-accent"
                            : ""
                        }
                      >
                        Cards
                      </span>
                    </button>

                  </Link>
                </li>
              )}

              {/* Market  */}
              {userData && userData?.pk ? (
                <li className="group">
                  {userData?.level >= 10 ? (
                    <Link href="/market">
                      <button className="text-jacarta-700 font-display hover:text-accent focus:text-accent dark:hover:text-accent dark:focus:text-accent flex items-center justify-between py-3.5 text-base dark:text-white lg:px-5">
                        <span
                          className={
                            isChildrenPageActive(route.asPath, "/market")
                              ? "text-accent dark:text-accent"
                              : ""
                          }
                        >
                          Market
                        </span>
                      </button>
                    </Link>
                  ) : (
                    <button onClick={() => marketToast()} className="text-jacarta-700 font-display hover:text-accent focus:text-accent dark:hover:text-accent dark:focus:text-accent flex items-center justify-between py-3.5 text-base dark:text-white lg:px-5">
                      <span
                        className={
                          isChildrenPageActive("/market", route.asPath)
                            ? "text-accent dark:text-accent"
                            : ""
                        }
                      >
                        Market
                      </span>
                    </button>
                  )}
                </li>
              ) : null}
              {/* Community */}
              <li className="js-nav-dropdown group relative">
                <button className="dropdown-toggle text-jacarta-700 font-display hover:text-accent focus:text-accent dark:hover:text-accent dark:focus:text-accent flex items-center justify-between p-3.5 text-base dark:text-white lg:px-5 w-full">
                  <span
                    className={
                      isParentPageActive(community.pages, route.asPath)
                        ? "text-accent dark:text-accent"
                        : ""
                    }
                  >
                    Community
                  </span>
                  <i className="lg:hidden">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      width={24}
                      height={24}
                      className="h-4 w-4 dark:fill-white"
                    >
                      <path fill="none" d="M0 0h24v24H0z" />
                      <path d="M12 13.172l4.95-4.95 1.414 1.414L12 16 5.636 9.636 7.05 8.222z" />
                    </svg>
                  </i>
                </button>
                <ul
                  className="dropdown-menu dark:bg-darker border-1 border-accent left-0 top-[85%] z-10 hidden min-w-[200px] gap-x-4 whitespace-nowrap rounded-xl bg-white transition-all will-change-transform group-hover:visible group-hover:opacity-100 lg:invisible lg:absolute lg:grid lg:translate-y-4 lg:py-4 lg:px-2 lg:opacity-0 lg:shadow-2xl lg:group-hover:translate-y-2 relative"
                  aria-labelledby="navDropdown-4"
                >
                  {community?.pages?.map((page) => (
                    <li key={page.id}>
                      <Link
                        href={page.path}
                        target={page.target ? page.target : "_self"}
                        className="dark:hover:bg-dark hover:text-accent focus:text-accent hover:bg-jacarta-50 flex items-center rounded-md px-5 py-2 transition-colors justify-between"
                      >

                        <span
                          className={`font-display ${isChildrenPageActive(page.path, route.asPath)
                            ? "!text-accent !dark:text-accent"
                            : "text-jacarta-700 dark:text-white"
                            } text-sm `}
                        >
                          {page.name}
                        </span>
                        {page.condition ? (
                          <span className="rounded bg-green py-1 px-2 text-tiny font-bold uppercase leading-none text-white ml-4">
                            new
                          </span>
                        ) : undefined}

                      </Link>
                    </li>
                  ))}
                  <li key={uuidv4()}>
                    <Link
                      href="https://blog.playchronogram.com/"
                      target="_blank"
                      className="dark:hover:bg-dark hover:text-accent focus:text-accent hover:bg-jacarta-50 flex items-center rounded-md px-5 py-2 transition-colors justify-between"
                    >
                      <span className="font-display text-jacarta-700 dark:text-white text-sm" >
                        Writings
                      </span>
                    </Link>
                  </li>
                  <li key={uuidv4()}>
                    <Link
                      href="https://discord.gg/EKa9zwrehc"
                      target="_blank"
                      className="dark:hover:bg-dark hover:text-accent focus:text-accent hover:bg-jacarta-50 flex items-center rounded-md px-5 py-2 transition-colors justify-between"
                    >
                      <span className="font-display text-jacarta-700 dark:text-white text-sm" >
                        Discord
                      </span>
                    </Link>
                  </li>
                </ul>
              </li>

              {/* Writings  */}
              <li className="group" key={uuidv4()}>
                {userData && userData?.pk ? (
                  <Link href="/shop">
                    <button className="text-jacarta-700 font-display hover:text-accent focus:text-accent dark:hover:text-accent dark:focus:text-accent flex items-center justify-between py-3.5 text-base dark:text-white lg:px-5">
                      <span
                        className={
                          isChildrenPageActive(route.asPath, "/shop")
                            ? "text-accent dark:text-accent"
                            : ""
                        }
                      >
                        Shop
                      </span>
                    </button>
                  </Link>
                ) : (
                  <div>
                    <Link className="text-white font-display hover:text-accent focus:text-accent flex items-center justify-between py-3.5 text-base lg:px-5"
                      href="https://discord.gg/EKa9zwrehc"
                      target="_blank"
                    >
                      <span className="">
                        Discord
                      </span>
                    </Link>
                  </div>
                )}
              </li>

            </ul>
          </nav>
          {/* End menu for desktop */}
        </div>
        {/* header menu conent end for desktop */}

        {!userData?.uname ? (
          <div className="hidden justify-end md:flex gap-2">
            {/* <!-- CTA --> */}
            <button
              className="hover:text-accent underline rounded-full py-3 px-8 text-center font-semibold text-white transition-all"
              onClick={() => dispatch(loginModalShow())}
            >
              Login
            </button>
            <button
              className="bg-accent shadow-accent-volume hover:bg-accent-dark w-auto rounded-full py-3 px-8 text-center font-semibold text-white transition-all"
              onClick={() => dispatch(continueGuestModalShow())}
            >
              Play&nbsp;as&nbsp;Guest
            </button>
          </div>
        ) : (
          <div className="ml-8 hidden items-center lg:flex xl:ml-12">
            {/* End metamask Wallet */}
            {userData && userData?.pk ? (
              <Link
                href="/shop"
                className="dropdown-toggle border-jacarta-100 hover:bg-accent focus:bg-accent group dark:hover:bg-accent ml-2 flex h-10 w-10 items-center justify-center rounded-full border bg-white transition-colors hover:border-transparent focus:border-transparent dark:border-transparent dark:bg-accent-dark eventsIconBox">
                <FontAwesomeIcon icon={faShop} className="inline-block eventsIcon eventsIcon18" />
              </Link>
            ) : (
              <div
                onClick={() => dispatch(registerModalShow())}
                className="dropdown-toggle border-jacarta-100 hover:bg-accent focus:bg-accent group dark:hover:bg-accent ml-2 flex h-10 w-10 items-center justify-center rounded-full border bg-white transition-colors hover:border-transparent focus:border-transparent dark:border-transparent dark:bg-accent-dark eventsIconBox">
                <FontAwesomeIcon icon={faShop} className="inline-block eventsIcon eventsIcon18" />
              </div>
            )}
            {userData && userData?.pk ? (
              <Events />
            ) : null}
            <div className="js-nav-dropdown group-dropdown relative">
              <button className="dropdown-toggle border-jacarta-100 hover:bg-accent focus:bg-accent group dark:hover:bg-accent ml-2 flex h-10 w-10 items-center justify-center rounded-full border bg-white transition-colors hover:border-transparent focus:border-transparent dark:border-transparent dark:bg-accent-dark">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width={24}
                  height={24}
                  className="fill-jacarta-700 h-4 w-4 transition-colors group-hover:fill-white group-focus:fill-white dark:fill-white"
                >
                  <path fill="none" d="M0 0h24v24H0z" />
                  <path d="M11 14.062V20h2v-5.938c3.946.492 7 3.858 7 7.938H4a8.001 8.001 0 0 1 7-7.938zM12 13c-3.315 0-6-2.685-6-6s2.685-6 6-6 6 2.685 6 6-2.685 6-6 6z" />
                </svg>
              </button>
              <div className="dropdown-menu dark:bg-darker border-1 border-accent group-dropdown-hover:opacity-100 group-dropdown-hover:visible !-right-4 !top-[85%] !left-auto z-10 min-w-[14rem] whitespace-nowrap rounded-xl bg-white transition-all will-change-transform before:absolute before:-top-3 before:h-3 before:w-full lg:absolute lg:grid lg:!translate-y-4 lg:py-4 lg:px-2 lg:shadow-2xl hidden lg:invisible lg:opacity-0">
                <div className="js-copy-clipboard font-display text-jacarta-700 mb-2 flex select-none items-center whitespace-nowrap px-5 leading-none dark:text-white">
                  {userData?.uname}
                </div>
                <div className="dark:border-accent border-1 mx-5 mb-6 rounded-lg border p-4">
                  <div className="js-copy-clipboard font-display text-jacarta-500 text-sm my-1 flex select-none items-center whitespace-nowrap leading-none dark:text-white">
                    Level: {userData?.level}
                  </div>
                  <div className="flex items-center mb-2 mt-1">
                    <div className="icon icon-ETH mr-1 iconSize">
                      <FontAwesomeIcon icon={faScroll} />
                    </div>
                    <span className="text-sm font-bold">
                      {userData.xp}/{userData.level * 100}
                    </span>
                  </div>
                  <span className="js-copy-clipboard font-display text-jacarta-500 text-sm my-1 mt-3 flex select-none items-center leading-none dark:text-white">
                    Balance:
                  </span>
                  <div className="flex items-center mb-1 mt-1">
                    <div className="icon icon-ETH mr-1 iconSize">
                      <FontAwesomeIcon icon={faCoins} />
                    </div>
                    <span className="text-sm font-bold">
                      {userData.chronos} Chronos
                    </span>
                  </div>
                  <div className="flex items-center mb-1">
                    <div className="icon icon-ETH mr-1 iconSize">
                      <FontAwesomeIcon icon={faExplosion} />
                    </div>
                    <span className="text-sm font-bold">
                      {userData.dynamites} Dynamites
                    </span>
                  </div>
                  <div className="flex items-center mb-1">
                    <div className="icon icon-ETH mr-1 iconSize">
                      <FontAwesomeIcon icon={faBomb} />
                    </div>
                    <span className="text-sm font-bold">
                      {userData.bombs} Bombs
                    </span>
                  </div>
                </div>
                <Link
                  href={userData && userData?.pk ? `/user/${userData.pk}` : "/"}
                  className="dark:hover:bg-dark hover:text-accent focus:text-accent hover:bg-jacarta-50 flex items-center space-x-2 rounded-md px-5 py-2 transition-colors"
                >

                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    width={24}
                    height={24}
                    className="fill-jacarta-700 h-4 w-4 transition-colors dark:fill-white"
                  >
                    <path fill="none" d="M0 0h24v24H0z" />
                    <path d="M11 14.062V20h2v-5.938c3.946.492 7 3.858 7 7.938H4a8.001 8.001 0 0 1 7-7.938zM12 13c-3.315 0-6-2.685-6-6s2.685-6 6-6 6 2.685 6 6-2.685 6-6 6z" />
                  </svg>
                  <span className="font-display text-jacarta-700 mt-1 text-sm dark:text-white">
                    My Profile
                  </span>

                </Link>
                <Link
                  href="/profile"
                  className="dark:hover:bg-dark hover:text-accent focus:text-accent hover:bg-jacarta-50 flex items-center space-x-2 rounded-md px-5 py-2 transition-colors"
                >

                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    width={24}
                    height={24}
                    className="fill-jacarta-700 h-4 w-4 transition-colors dark:fill-white"
                  >
                    <path fill="none" d="M0 0h24v24H0z" />
                    <path d="M9.954 2.21a9.99 9.99 0 0 1 4.091-.002A3.993 3.993 0 0 0 16 5.07a3.993 3.993 0 0 0 3.457.261A9.99 9.99 0 0 1 21.5 8.876 3.993 3.993 0 0 0 20 12c0 1.264.586 2.391 1.502 3.124a10.043 10.043 0 0 1-2.046 3.543 3.993 3.993 0 0 0-3.456.261 3.993 3.993 0 0 0-1.954 2.86 9.99 9.99 0 0 1-4.091.004A3.993 3.993 0 0 0 8 18.927a3.993 3.993 0 0 0-3.457-.26A9.99 9.99 0 0 1 2.5 15.121 3.993 3.993 0 0 0 4 11.999a3.993 3.993 0 0 0-1.502-3.124 10.043 10.043 0 0 1 2.046-3.543A3.993 3.993 0 0 0 8 5.071a3.993 3.993 0 0 0 1.954-2.86zM12 15a3 3 0 1 0 0-6 3 3 0 0 0 0 6z" />
                  </svg>
                  <span className="font-display text-jacarta-700 mt-1 text-sm dark:text-white">
                    Edit Profile
                  </span>

                </Link>
                <Link
                  href="#"
                  className="dark:hover:bg-dark hover:text-accent focus:text-accent hover:bg-jacarta-50 flex items-center space-x-2 rounded-md px-5 py-2 transition-colors"
                  onClick={() => handleSignOut()}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    width={24}
                    height={24}
                    className="fill-jacarta-700 h-4 w-4 transition-colors dark:fill-white"
                  >
                    <path fill="none" d="M0 0h24v24H0z" />
                    <path d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zM7 11V8l-5 4 5 4v-3h8v-2H7z" />
                  </svg>
                  <span className="font-display text-jacarta-700 mt-1 text-sm dark:text-white">
                    Sign out
                  </span>

                </Link>
              </div>
            </div>
          </div>
        )}
        {/* End header right content (metamask and other) for desktop */}



        <div className="ml-auto flex justify-center lg:hidden">
          {userData && userData?.pk ? (
            <Link
              href="/shop"
              className="dropdown-toggle border-jacarta-100 hover:bg-accent focus:bg-accent-dark group dark:hover:bg-accent-dark ml-2 flex h-10 w-10 items-center justify-center rounded-full border bg-white transition-colors hover:border-transparent focus:border-transparent dark:border-transparent dark:bg-accent eventsIconBox">
              <FontAwesomeIcon icon={faShop} className="inline-block eventsIcon eventsIcon18" />
            </Link>
          ) : null}
          {userData && userData?.pk ? (
            <Link
              href="/events"
              className={`${isEventActive ? "events-btn-glow" : ""} dropdown-toggle border-jacarta-100 hover:bg-accent focus:bg-accent-dark group dark:hover:bg-accent-dark ml-2 flex h-10 w-10 items-center justify-center rounded-full border bg-white transition-colors hover:border-transparent focus:border-transparent dark:border-transparent dark:bg-accent eventsIconBox`}>
              <FontAwesomeIcon icon={faCalendarDays} className="inline-block eventsIcon" />
            </Link>
          ) : null}
          <button
            className="js-mobile-toggle border-jacarta-100 hover:bg-accent dark:hover:bg-accent-dark focus:bg-accent-dark group ml-2 flex h-10 w-10 items-center justify-center rounded-full border bg-white transition-colors hover:border-transparent focus:border-transparent dark:border-transparent dark:bg-accent"
            aria-label="open mobile menu"
            onClick={() => setToggle(true)}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              width={24}
              height={24}
              className="fill-jacarta-700 h-4 w-4 transition-colors group-hover:fill-white group-focus:fill-white dark:fill-white"
            >
              <path fill="none" d="M0 0h24v24H0z" />
              <path d="M18 18v2H6v-2h12zm3-7v2H3v-2h18zm-3-7v2H6V4h12z" />
            </svg>
          </button>
        </div>
        {/* End mobile toggle button */}
      </div>
    </header>
    {/* main desktop menu end */}

    {/* start mobile menu and it's other materials  */}
    <div
      className={`lg:hidden js-mobile-menu dark:bg-jacarta-800 invisible fixed inset-0 z-20 ml-auto items-center bg-white opacity-0 lg:visible lg:relative lg:inset-auto lg:bg-transparent lg:opacity-100 dark:lg:bg-transparent ${toggle ? "nav-menu--is-open" : "hidden"
        }`}
    >
      {process.env.NEXT_PUBLIC_IS_PROD === "true" ? (
        <noscript><iframe src="https://www.googletagmanager.com/ns.html?id=GTM-PDP789K" height="0" width="0" style={{ display: "none", visibility: "hidden" }}></iframe></noscript>
      ) : null}
      <div className="t-0 dark:bg-jacarta-800 fixed left-0 z-10 flex w-full items-center justify-between bg-white p-6 lg:hidden">
        <div className="dark:hidden pt-1">
          <Image
            src={Logo}
            width={200}
            height={54}
            alt="Chronogram"
            className="h-auto "
          />
        </div>

        <div className="hidden dark:block pt-1">
          <Image
            src={WhiteLogo}
            width={200}
            height={54}
            alt="Chronogram"
          />
        </div>

        <button
          className="js-mobile-close border-jacarta-100 hover:bg-accent focus:bg-accent group dark:hover:bg-accent ml-2 flex h-10 w-10 items-center justify-center rounded-full border bg-white transition-colors hover:border-transparent focus:border-transparent dark:border-transparent dark:bg-white/[.15]"
          onClick={() => setToggle(false)}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            width={24}
            height={24}
            className="fill-jacarta-700 h-4 w-4 transition-colors group-hover:fill-white group-focus:fill-white dark:fill-white"
          >
            <path fill="none" d="M0 0h24v24H0z" />
            <path d="M12 10.586l4.95-4.95 1.414 1.414-4.95 4.95 4.95 4.95-1.414 1.414-4.95-4.95-4.95 4.95-1.414-1.414 4.95-4.95-4.95-4.95L7.05 5.636z" />
          </svg>
        </button>
      </div>
      {/* mobile menu top header content */}
      {!userData && !userData?.pk ? (
        <div className="relative mt-28 mb-5 w-full lg:hidden flex gap-x-2 items-center justify-stretch">
          {/* <!-- CTA --> */}
          <button
            className="w-full hover:text-accent underline rounded-full py-3 px-8 text-center font-semibold text-white transition-all"
            onClick={() => dispatch(loginModalShow())}
          >
            Login
          </button>
          <button
            className="w-full bg-accent shadow-accent-volume hover:bg-accent-dark rounded-full py-3 px-8 text-center font-semibold text-white transition-all"
            onClick={() => dispatch(continueGuestModalShow())}
          >
            Play&nbsp;as&nbsp;Guest
          </button>
        </div>
      ) : null}
      {/* End search form mobile menu  */}
      <nav className="navbar w-full font-display text-base">
        <ul className={`flex flex-col lg:flex-row ${userData && userData?.pk ? "mt-28" : ""}`}>

          <li className={`js-nav-dropdown group ${userData && userData?.pk ? "relative" : "hidden"}`}>
            <button
              onClick={() => mobileCollapse(play.id)}
              className="dropdown-toggle bg-accent shadow-accent-volume text-white rounded-full hover:bg-accent-dark focus:bg-accent-dark flex items-center justify-between p-3.5 w-full"
            >
              <span>
                {play.name}
              </span>
              <i className="lg:hidden">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width={24}
                  height={24}
                  className="h-4 w-4 dark:fill-white"
                >
                  <path fill="none" d="M0 0h24v24H0z" />
                  <path d="M12 13.172l4.95-4.95 1.414 1.414L12 16 5.636 9.636 7.05 8.222z" />
                </svg>
              </i>
            </button>
            <ul className={`dropdown-menu dark:bg-darker border-1 border-accent left-0 top-0 z-10 w-full gap-x-3.5 whitespace-nowrap rounded-3xl transition-all py-3 relative ${isCollapse === play.id ? "block mt-4" : "hidden"}`}
            >
              {play?.pages?.map((page) => (
                <li key={page.id} onClick={() => setToggle(false)} className="">
                  <Link
                    onClick={page.action ?? null}
                    href={page.action ? "#" : page.path}
                    className="dark:hover:bg-dark hover:text-accent focus:text-accent hover:bg-jacarta-50 flex items-center rounded-xl px-5 py-1.5 transition-colors"
                  >
                    <span
                      className={`font-display ${isChildrenPageActive(route.asPath, page.path)
                        ? "text-accent"
                        : "text-white"
                        }`}
                    >
                      {page.name}
                    </span>
                    {page.condition ? (
                      <span className="rounded bg-green py-1 px-2 text-tiny font-bold uppercase leading-none text-white ml-4">
                        new
                      </span>
                    ) : undefined}

                  </Link>
                </li>
              ))}
            </ul>
          </li>

          <li className={`group`} onClick={() => setToggle(false)}>
            <Link href={userData && userData?.pk ? `/user/${userData.pk}` : "/"} >
              <button className="text-jacarta-700 font-display hover:text-accent focus:text-accent dark:hover:text-accent dark:focus:text-accent flex items-center justify-between p-3.5 text-base dark:text-white lg:px-5">
                <span
                  className={
                    isChildrenPageActive(userData && userData?.pk ? `/user/${userData.pk}` : "/", route.asPath)
                      ? "text-accent dark:text-accent"
                      : ""
                  }
                >
                  {userData && userData?.pk ? "Profile" : "Home"}
                </span>
              </button>

            </Link>
          </li>

          {userData && token ? (
            <li className="js-nav-dropdown group relative">
              <button
                onClick={() => mobileCollapse(cards.id)}
                className="dropdown-toggle text-jacarta-700 font-display hover:text-accent focus:text-accent dark:hover:text-accent dark:focus:text-accent flex items-center justify-between p-3.5 text-base dark:text-white lg:px-5 w-full"
              >
                <span
                  className={
                    isParentPageActive(cards.pages, route.asPath)
                      ? "text-accent dark:text-accent"
                      : ""
                  }
                >
                  {cards.name}
                </span>
                <i className="lg:hidden">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    width={24}
                    height={24}
                    className="h-4 w-4 dark:fill-white"
                  >
                    <path fill="none" d="M0 0h24v24H0z" />
                    <path d="M12 13.172l4.95-4.95 1.414 1.414L12 16 5.636 9.636 7.05 8.222z" />
                  </svg>
                </i>
              </button>
              <ul
                className={`dropdown-menu dark:bg-darker border-1 border-accent left-0 top-0 z-10 w-full gap-x-3.5 whitespace-nowrap rounded-3xl transition-all py-3 relative ${isCollapse === cards.id ? "block" : "hidden"
                  }`}
              >
                {cards?.pages?.map((page) => (
                  <li key={page.id} onClick={() => setToggle(false)}>
                    <Link
                      href={page.path}
                      className="dark:hover:bg-dark hover:text-accent focus:text-accent hover:bg-jacarta-50 flex items-center rounded-md px-5 py-2 transition-colors"
                    >

                      <span
                        className={`${isChildrenPageActive(route.asPath, page.path)
                          ? "text-accent dark:text-accent"
                          : "text-jacarta-700"
                          } dark:text-white`}
                      >
                        {page.name}
                      </span>
                      {page.condition ? (
                        <span className="rounded bg-green py-1 px-2 text-tiny font-bold uppercase leading-none text-white ml-4">
                          new
                        </span>
                      ) : undefined}

                    </Link>
                  </li>
                ))}
              </ul>
            </li>
          ) : (
            <li className="group" onClick={() => setToggle(false)}>
              <Link href="/cards" >
                <button className="text-jacarta-700 font-display hover:text-accent focus:text-accent dark:hover:text-accent dark:focus:text-accent flex items-center justify-between p-3.5 text-base dark:text-white lg:px-5">
                  <span
                    className={
                      isChildrenPageActive("/cards", route.asPath)
                        ? "text-accent dark:text-accent"
                        : ""
                    }
                  >
                    Cards
                  </span>
                </button>

              </Link>
            </li>
          )}

          {userData && userData?.pk ? (
            <li className="group" onClick={() => setToggle(false)}>
              {userData?.level >= 10 ? (
                <Link href="/market">
                  <button className="text-jacarta-700 font-display hover:text-accent focus:text-accent dark:hover:text-accent dark:focus:text-accent flex items-center justify-between p-3.5 text-base dark:text-white lg:px-5">
                    <span
                      className={
                        isChildrenPageActive("/market", route.asPath)
                          ? "text-accent dark:text-accent"
                          : ""
                      }
                    >
                      Market
                    </span>
                  </button>
                </Link>
              ) : (
                <button onClick={() => marketToast()} className="text-jacarta-700 font-display hover:text-accent focus:text-accent dark:hover:text-accent dark:focus:text-accent flex items-center justify-between p-3.5 text-base dark:text-white lg:px-5">
                  <span
                    className={
                      isChildrenPageActive("/market", route.asPath)
                        ? "text-accent dark:text-accent"
                        : ""
                    }
                  >
                    Market
                  </span>
                </button>
              )}
            </li>
          ) : null}

          <li className="js-nav-dropdown group relative">
            <button
              onClick={() => mobileCollapse(community.id)}
              className="dropdown-toggle text-jacarta-700 font-display hover:text-accent focus:text-accent dark:hover:text-accent dark:focus:text-accent flex items-center justify-between p-3.5 text-base dark:text-white lg:px-5 w-full"
            >
              <span
                className={
                  isParentPageActive(community.pages, route.asPath)
                    ? "text-accent dark:text-accent"
                    : ""
                }
              >
                {community.name}
              </span>
              <i className="lg:hidden">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width={24}
                  height={24}
                  className="h-4 w-4 dark:fill-white"
                >
                  <path fill="none" d="M0 0h24v24H0z" />
                  <path d="M12 13.172l4.95-4.95 1.414 1.414L12 16 5.636 9.636 7.05 8.222z" />
                </svg>
              </i>
            </button>
            <ul
              className={`dropdown-menu dark:bg-darker border-1 border-accent left-0 top-0 z-10 w-full gap-x-3.5 whitespace-nowrap rounded-3xl transition-all py-3 relative ${isCollapse === community.id ? "block" : "hidden"
                }`}
            >
              {community?.pages?.map((page) => (
                <li key={page.id} onClick={() => setToggle(false)}>
                  <Link
                    href={page.path}
                    target={page.target ? page.target : "_self"}
                    className="dark:hover:bg-dark hover:text-accent focus:text-accent hover:bg-jacarta-50 flex items-center rounded-md px-5 py-2 transition-colors justify-between"
                  >

                    <span
                      className={
                        isChildrenPageActive(page.path, route.asPath)
                          ? "text-accent dark:text-accent"
                          : ""
                      }
                    >
                      {page.name}
                    </span>
                    {page.condition ? (
                      <span className="rounded bg-green py-1 px-2 text-tiny font-bold uppercase leading-none text-white ml-4">
                        new
                      </span>
                    ) : undefined}

                  </Link>
                </li>
              ))}
              <li key={uuidv4()} onClick={() => setToggle(false)}>
                <Link
                  href="https://blog.playchronogram.com/"
                  target="_blank"
                  className="dark:hover:bg-dark hover:text-accent focus:text-accent hover:bg-jacarta-50 flex items-center rounded-md px-5 py-2 transition-colors justify-between"
                >
                  <span>
                    Writings
                  </span>
                </Link>
              </li>
              <li key={uuidv4()} onClick={() => setToggle(false)}>
                <Link
                  href="https://discord.gg/EKa9zwrehc"
                  target="_blank"
                  className="dark:hover:bg-dark hover:text-accent focus:text-accent hover:bg-jacarta-50 flex items-center rounded-md px-5 py-2 transition-colors justify-between"
                >
                  <span>
                    Discord
                  </span>
                </Link>
              </li>
            </ul>
          </li>

          <li className="group" onClick={() => setToggle(false)}>
            {userData && userData?.pk ? (
              <Link href="/shop" target="_blank" >
                <button className="text-jacarta-700 font-display hover:text-accent focus:text-accent dark:hover:text-accent dark:focus:text-accent flex items-center justify-between p-3.5 text-base dark:text-white lg:px-5">
                  <span
                    className={
                      isChildrenPageActive("/shop", route.asPath)
                        ? "text-accent dark:text-accent"
                        : ""
                    }
                  >
                    Shop
                  </span>
                </button>
              </Link>
            ) : (
              <div>
                <Link href="https://discord.gg/EKa9zwrehc" target="_blank" className="text-jacarta-700 font-display hover:text-accent focus:text-accent dark:hover:text-accent dark:focus:text-accent flex items-center justify-between p-3.5 text-base dark:text-white lg:px-5">
                  <span
                    className={
                      isChildrenPageActive("/shop", route.asPath)
                        ? "text-accent dark:text-accent"
                        : ""
                    }
                  >
                    Discord
                  </span>
                </Link>
              </div>
            )}
          </li>

          {userData && userData?.pk ? (
            <li className="group" onClick={() => setToggle(false)}>
              <Link href="/profile">

                <button className="text-jacarta-700 font-display hover:text-accent focus:text-accent dark:hover:text-accent dark:focus:text-accent flex items-center justify-between p-3.5 text-base dark:text-white lg:px-5">
                  <span
                    className={
                      isChildrenPageActive("/profile", route.asPath)
                        ? "text-accent dark:text-accent"
                        : ""
                    }
                  >
                    Edit Profile
                  </span>
                </button>

              </Link>
            </li>
          ) : null}

          {userData && userData?.pk ? (
            <li className="group" onClick={() => setToggle(false)}>
              <Link
                href="#"
                onClick={() => handleSignOut()}
              >

                <button className="text-jacarta-700 font-display hover:text-accent focus:text-accent dark:hover:text-accent dark:focus:text-accent flex items-center justify-between p-3.5 text-base dark:text-white lg:px-5">
                  Sign out
                </button>

              </Link>
            </li>
          ) : null}

        </ul>
      </nav>
      {/* End navbar mobile menu  */}
      {/* mt-10 w-full lg:hidden */}
    </div>
    {/* End mobile menu and it's other materials */}

    {/* START MATCH POPUP */}
    {showMatchPopup ?
      <CreateMatchManager cancelAction={() => setshowMatchPopup(false)} /> :
      null
    }
    {/* END START MATCH POPUP */}
  </>;
}
