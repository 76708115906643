import Link from "next/link";
import { useState, useEffect } from "react";
import { footerMenuList, socialIcons, footerMenuLogedList } from "../data/footer_data";
import Image from "next/image";
import { ReactSession } from "react-client-session";

const Footer = () => {
  const [user, setUser] = useState(null);
  const [isMobile, setIsMobile] = useState(false);
  ReactSession.setStoreType("localStorage");


  useEffect(() => {
    if (ReactSession.get("user")) {
      setUser(ReactSession.get("user"));
    }
  }, []);

  useEffect(() => {
    if (window.innerWidth <= 600) {
      setIsMobile(true)
    }
  }, []);
  return (
    <>
      {/* <!-- Footer --> */}

      <footer className="dark:bg-darkest page-footer bg-white">
        <div className="container">
          <div className="grid grid-cols-6 gap-x-7 gap-y-14 pt-24 pb-12 md:grid-cols-12">
            <div className="col-span-3 md:col-span-4">
              {/* <!-- Logo --> */}
              <Link href="#" className="mb-6 inline-block">
                <Image
                  width={50}
                  height={50}
                  src="/images/logo-outline-256.webp"
                  className="dark:hidden"
                  alt="Chronogram | Marketplace"
                />
              </Link>

              <Link href="#" className=" mb-6 inline-block">
                { isMobile ? (
                  <Image
                    width={50}
                    height={50}
                    src="/images/Dark-Logo-Icon.png"
                    className="hidden dark:block"
                    alt="Chronogram | Marketplace"
                  />
                ) : (
                  <Image
                    width={50}
                    height={50}
                    src="/images/logo-outline-256.webp"
                    className="hidden dark:block"
                    alt="Chronogram | Marketplace"
                  />
                ) }
              </Link>
              <p className="dark:text-jacarta-300 mb-12">
                Join Chronogram for free and start discovering, collecting, and trading the cards of ancient mythology.
              </p>

              {/* <!-- Socials --> */}
              <div className="flex space-x-5">
                {socialIcons.map((item) => {
                  const { id, href, text } = item;
                  return (
                    <Link
                      href={href}
                      key={id}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="group cursor-pointer"
                    >
                      <svg className="icon group-hover:fill-accent fill-jacarta-300 h-5 w-5 dark:group-hover:fill-white">
                        <use xlinkHref={`/icons.svg#icon-${text}`}></use>
                      </svg>
                    </Link>
                  );
                })}
              </div>
            </div>

            { user ? (<>
              {footerMenuLogedList.map((single) => (
                <div
                  className={`col-span-full sm:col-span-3 md:col-span-3 ${single.diffClass}`}
                  key={single.id}
                >
                  <h3 className="font-display text-jacarta-700 mb-6 text-sm dark:text-white">
                    {single.title}
                  </h3>
                  <ul className="dark:text-jacarta-300 flex flex-col space-y-1">
                    {single.list.map((item) => {
                      const { id, href, text } = item;
                      return (
                        <li key={id}>
                          <Link
                            href={href}
                            target="_blank"
                            className="hover:text-accent dark:hover:text-white"
                          >
                            {text}
                          </Link>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              ))}
            </>) : (<>
              {footerMenuList.map((single) => (
                <div
                  className={`col-span-full sm:col-span-3 md:col-span-3 ${single.diffClass}`}
                  key={single.id}
                >
                  <h3 className="font-display text-jacarta-700 mb-6 text-sm dark:text-white">
                    {single.title}
                  </h3>
                  <ul className="dark:text-jacarta-300 flex flex-col space-y-1">
                    {single.list.map((item) => {
                      const { id, href, text } = item;
                      return (
                        <li key={id}>
                          <Link
                            href={href}
                            target="_blank"
                            className="hover:text-accent dark:hover:text-white"
                          >
                            {text}
                          </Link>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              ))}
            </>)}
          </div>

          <div className="flex flex-col items-center justify-between space-y-2 py-8 sm:flex-row sm:space-y-0">
            <span className="dark:text-jacarta-400 text-sm">
              
            </span>

            <ul className="dark:text-jacarta-400 flex flex-wrap space-x-4 text-sm">
              <li>
                <Link
                  href="/terms"
                  className="hover:text-accent dark:hover:text-white"
                >
                  Terms
                </Link>
              </li>
              <li>
                <Link
                  href="/privacy"
                  className="hover:text-accent dark:hover:text-white"
                >
                  Privacy
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
