//import React, { useState } from "react";
//import { useSelector, useDispatch } from "react-redux";
import Image from "next/image";

const EventCollectModal = ({ resultData, onCloseAction, title }) => {
  const handleClose = () => {
    if (onCloseAction) {
      onCloseAction();
    }
  };

  const rarityArray = ["Common", "Rare", "Epic", "Legendary"]

  return (
    <div className={resultData ? "modal fade show block" : "modal fade"}>
      <div className="inset-0 absolute !cursor-arrow"
        onClick={() => handleClose()}
      ></div>
      <div className="modal-dialog max-w-\[90\.375rem\]">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">
              {title}
            </h5>
            <button
              type="button"
              className="btn-close"
              onClick={() => handleClose()}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                width="24"
                height="24"
                className="fill-jacarta-700 h-6 w-6 dark:fill-white"
              >
                <path fill="none" d="M0 0h24v24H0z"></path>
                <path d="M12 10.586l4.95-4.95 1.414 1.414-4.95 4.95 4.95 4.95-1.414 1.414-4.95-4.95-4.95 4.95-1.414-1.414 4.95-4.95-4.95-4.95L7.05 5.636z"></path>
              </svg>
            </button>
          </div>

          {/* <!-- Body --> */}
          <div className="modal-body p-6">
            <div className="grid grid-cols-3 gap-[1.875rem] md:grid-cols-3 lg:grid-cols-3">
              {resultData?.card?.pk ? (
                <div className={`relative bg-dark border-jacarta-600 hover:border-accent cardBorder block border p-[0.75rem] transition-shadow`}>
                  <a className="w-full" href={`/cards/${resultData?.card?.setId}/${resultData?.card?.pk}?versions=0`} target="_blank">
                    <figure className="relative">
                      <Image
                        width={230}
                        height={230}
                        src={resultData?.card?.image ? resultData.card.image : resultData.card?.previewCards[0]?.image}
                        alt={resultData.card.name}
                        className="w-full object-cover"
                      />
                      {resultData?.card?.count === 1 ? (
                        <Image
                          width={50}
                          height={50}
                          src="/images/new_ribbon.png"
                          alt="new"
                          className="isNewDigCard"
                        />
                      ) : null}
                      {resultData?.card?.videoUrl ? (
                        <video autoPlay loop muted className="cardDetailVideo">
                          <source src={resultData.card.videoUrl} type="video/mp4" />
                        </video>
                      ) : null}
                    </figure>
                    <div className="mt-2 items-center justify-between">
                      <span className="block font-display text-jacarta-700 hover:text-accent text-base dark:text-white text-center">
                        {rarityArray[resultData.card.rarity]} card
                      </span>
                    </div>
                  </a>
                </div>
              ) : null}
              {resultData?.dynamites ? (
                <div className={`relative dark:bg-jacarta-700 dark:border-jacarta-700 border-jacarta-100 cardBorder block border bg-white p-[0.75rem] transition-shadow`}>
                  <button className="w-full">
                    <figure className="relative">
                      <Image
                        width={230}
                        height={230}
                        src="/images/icons/dynamite.jpg"
                        alt="item 5"
                        className="w-full rounded-[0.625rem] object-cover"
                      />
                    </figure>
                    <div className="mt-2 items-center justify-between">
                      <span className="font-display text-jacarta-700 hover:text-accent text-base dark:text-white">
                        Dynamite
                      </span>
                    </div>
                  </button>
                  <span className="itemCount font-medium">{resultData.dynamites}</span>
                </div>
              ) : null}
              {resultData?.bombs ? (
                <div className={`relative dark:bg-jacarta-700 dark:border-jacarta-700 border-jacarta-100 cardBorder block border bg-white p-[0.75rem] transition-shadow`}>
                  <button className="w-full">
                    <figure className="relative">
                      <Image
                        width={230}
                        height={230}
                        src="/images/icons/bomb.jpg"
                        alt="item 5"
                        className="w-full rounded-[0.625rem] object-cover"
                      />
                    </figure>
                    <div className="mt-2 items-center justify-between">
                      <span className="font-display text-jacarta-700 hover:text-accent text-base dark:text-white">
                        Bomb
                      </span>
                    </div>
                  </button>
                  <span className="itemCount font-medium">{resultData.bombs}</span>
                </div>
              ) : null}
              {resultData?.xp ? (
                <div className={`relative dark:bg-jacarta-700 dark:border-jacarta-700 border-jacarta-100 cardBorder block border bg-white p-[0.75rem] transition-shadow`}>
                  <button className="w-full">
                    <figure className="relative">
                      <Image
                        width={230}
                        height={230}
                        src="/images/icons/XP.jpg"
                        alt="item 5"
                        className="w-full rounded-[0.625rem] object-cover"
                      />
                    </figure>
                    <div className="mt-2 items-center justify-between">
                      <span className="font-display text-jacarta-700 hover:text-accent text-base dark:text-white">
                        XP: {resultData.xp}
                      </span>
                    </div>
                  </button>
                </div>
              ) : null}
              {resultData?.chronos ? (
                <div className={`relative dark:bg-jacarta-700 dark:border-jacarta-700 border-jacarta-100 cardBorder block border bg-white p-[0.75rem] transition-shadow`}>
                  <button className="w-full">
                    <figure className="relative">
                      <Image
                        width={230}
                        height={230}
                        src="/images/icons/coins.jpg"
                        alt="item 5"
                        className="w-full rounded-[0.625rem] object-cover"
                      />
                    </figure>
                    <div className="mt-2 items-center justify-between">
                      <span className="font-display text-jacarta-700 hover:text-accent text-base dark:text-white">
                        Chronos: {resultData.chronos}
                      </span>
                    </div>
                  </button>
                </div>
              ) : null}
            </div>

          </div>
          {/* <!-- end body --> */}

          <div className="modal-footer">
            <div className="flex items-center justify-center space-x-4">
              <button
                onClick={() => handleClose()}
                type="button"
                className="bg-accent shadow-accent-volume hover:bg-accent-dark rounded-full py-3 px-8 text-center font-semibold text-white transition-all"
              >
                Claim
              </button>
            </div>
          </div>

        </div>
      </div>
    </div>

  );
};

export default EventCollectModal;
