export default function rarityColor(rarity) {
    if (rarity >= 0) {
        switch (rarity) {
            case 0:
                return 'rarity-common-color'

            case 1:
                return 'rarity-rare-color'

            case 2:
                return 'rarity-epic-color'

            case 3:
                return 'rarity-legendary-color'

            case 4:
                return 'rarity-unique-color'

            default:
                return 'rarity-common-color';
        }
    }

    return '';
}

export function getRankFrame(playerRankName) {
    const rankNameAndLevel = playerRankName?.split(' ');
    if (rankNameAndLevel && rankNameAndLevel[0]) {
        return `/images/match/frames/frame_${rankNameAndLevel[0].toLowerCase()}.png`;
    }
    else
        return `/images/match/frames/frame_recruit.png`;
}

export function getRankBadge(playerRankName) {
    const rankNameAndLevel = playerRankName?.split(' ');
    if (rankNameAndLevel && rankNameAndLevel[0]) {

        return `/images/rank/badge_${rankNameAndLevel[0].toLowerCase()}.png`;
    }
    else
        return `/images/rank/badge_recruit.png`;
}

export function calculateProgressValue(count, maxValue) {
    if ((count / maxValue * 100.0) >= 100) {
        return 100
    } else {
        return (Math.round(count / maxValue * 100.0))
    }
}

export function storageAvailable(type) {
    let storage;
    try {
        storage = window[type];
        const x = '__storage_test__';
        storage.setItem(x, x);
        storage.removeItem(x);
        return true;
    }
    catch (e) {
        return e instanceof DOMException && (
            e.code === 22 ||
            e.code === 1014 ||
            e.name === 'QuotaExceededError' ||
            e.name === 'NS_ERROR_DOM_QUOTA_REACHED') &&
            (storage && storage.length !== 0);
    }
}