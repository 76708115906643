import Link from "next/link";
import { useDispatch, useSelector } from "react-redux";
import { fillInfoPopupData, newcomerModalShow, questsModalShow, joinDiscordCodeModalShow } from "../../redux/counterSlice";
import Image from "next/image";
import InfoPopup from '../info-popup/info-popup';
import { useState } from "react";
export default function EventsGrid({ className = "" }) {
  const { userData } = useSelector((state) => state.counter);
  const dispatch = useDispatch();

  function handleShowInfoPopup(title, body, image) {
    if (title || body) {
      dispatch(fillInfoPopupData({
        //title: title,
        body: `<strong class="text-lg">${title}:</strong><br/><br/>${body}`,
        image: image,
        className: "!text-left",
      }));
    }
  }

  const handleShowNewcomer = () => {
    dispatch(newcomerModalShow())
  };

  const showQuestsPopup = () => {
    if (userData?.quests) {
      dispatch(questsModalShow())
    }
  };

  return (
    <>
      <div className={`md:overflow-y-auto md:max-h-[75vh] md:w-[350px] md:min-w-[350px] flex flex-col gap-5 w-full mx-auto items-stretch ${className}`}>
        <div className="font-display text-xl md:text-base whitespace-nowrap text-white">
          Active Events
        </div>
        <div className="w-full flex flex-col justify-start items-stretch gap-2">
          {/* CHALLENGE BANNER */}
          {userData?.quests && userData?.quests?.length > 0 ? (
            <button className={`w-full relative ${userData?.quests?.every((quest) => !quest.claimed) ? "quests-banner" : ""}`}
              onClick={showQuestsPopup}>
              <Image
                width={266}
                height={80}
                loading='lazy'
                src="https://chronogram.s3.amazonaws.com/images/quest_banner.webp"
                alt="Quests Banner"
                className="w-full object-contain"
              />
              {userData?.quests?.every((quest) => quest.claimed) ? (<>
                <div className="imageDarkBg"></div>
                <div
                  className="completedNewcommer flex h-[1.125rem] w-[1.125rem] ml-1 items-center justify-center rounded-full border-2 border-white bg-green dark:border-jacarta-600"
                  title="Challenge Completed"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    width={24}
                    height={24}
                    className="h-[.875rem] w-[.875rem] fill-white"
                  >
                    <path fill="none" d="M0 0h24v24H0z" />
                    <path d="M10 15.172l9.192-9.193 1.415 1.414L10 18l-6.364-6.364 1.414-1.414z" />
                  </svg>
                </div>
              </>) : null}
            </button>
          ) : null}
          {/* NEWCOMER BANNER */}
          <button className={`relative ${!userData?.collectedOnboarding ? "newcomer-banner" : ""}`}
            onClick={() => handleShowNewcomer()}
          >
            <Image
              width={266}
              height={80}
              priority
              src="https://chronogram.s3.amazonaws.com/images/Newcommers_banner.webp"
              alt="gradient"
              className="w-full object-contain"
            />
            {userData?.collectedOnboarding ? (<>
              <div className="imageDarkBg"></div>
              <div
                className="completedNewcommer flex h-[1.125rem] w-[1.125rem] ml-1 items-center justify-center rounded-full border-2 border-white bg-green dark:border-jacarta-600"
                title="Newcommers Completed"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width={24}
                  height={24}
                  className="h-[.875rem] w-[.875rem] fill-white"
                >
                  <path fill="none" d="M0 0h24v24H0z" />
                  <path d="M10 15.172l9.192-9.193 1.415 1.414L10 18l-6.364-6.364 1.414-1.414z" />
                </svg>
              </div>
            </>) : null}
          </button>
          {/* LIVE EVENT BANNER */}
          {userData?.event?.imageUrl ? (
            <button className="w-full relative"
              onClick={() => handleShowInfoPopup(userData?.event?.name ?? "", userData?.event?.description ?? "", userData.event.imageUrl)}>
              <Image
                width={266}
                height={80}
                priority
                src={userData.event.imageUrl}
                alt={userData.event.name}
                className="w-full object-contain"
              />
            </button>
          ) : null}
          {/* NEW PLAYER BUNDLE BANNER */}
          {userData?.canBuyNewPlayerBundle ? (
            <Link
              href="/shop"
              className="relative"
            >
              <Image
                width={350}
                height={100}
                priority
                src="https://chronogram.s3.amazonaws.com/images/bundle_1_banner.webp"
                alt="gradient"
                className="w-full object-contain"
              />
            </Link>
          ) : null}
          {/* DEMO BUNDLE BANNER */}
          {userData?.canBuyDemolitionBundle && !userData?.canBuyNewPlayerBundle ? (
            <Link
              href="/shop"
              className="relative"
            >
              <Image
                width={266}
                height={80}
                priority
                src="https://chronogram.s3.amazonaws.com/images/bundle_2_banner.webp"
                alt="gradient"
                className="w-full object-contain"
              />
            </Link>
          ) : null}
          {/* DISCORD BANNER */}
          {userData?.canClaimDiscordCode ? (
            <button
              onClick={() => dispatch(joinDiscordCodeModalShow())}
              className="relative w-full"
            >
              <Image
                width={266}
                height={80}
                priority
                src="https://chronogram.s3.amazonaws.com/images/discord_banner.webp"
                alt="gradient"
                className="w-full object-contain"
              />
            </button>
          ) : null}
        </div>
      </div>
    </>
  );
}
