import { useDispatch } from "react-redux";
import { useState } from "react";
import { fillInfoPopupData } from "../../redux/counterSlice";
import Image from "next/image";

var closeAction = null;

export function setInfoPopupCloseAction(action) {
    closeAction = action;
}

export default function InfoPopup({ popupData }) {
    const dispatch = useDispatch();
    const [loadingImage, setLoadingImage] = useState(true);

    function onClose() {
        dispatch(fillInfoPopupData(null));
        setLoadingImage(true);
        if (closeAction) {
            closeAction();
            closeAction = null;
        }
    }

    return (
        <>
            {popupData && (popupData.title || popupData.body) ?
                <div className={`absolute z-[60] inset-0 backdrop-blur bg-[#00000080] flex items-center justify-center ${popupData?.className ? popupData.className : ""}`}>
                    <div className="inset-0 absolute !cursor-arrow"
                        onClick={() => { onClose() }}
                    ></div>
                    <div className='relative md:min-w-[320px] md:max-w-[640px] flex flex-col bg-darker border border-accent-darker rounded-xl m-auto w-[88%] md:w-auto md:mx-8 overflow-hidden'>
                        {popupData.title ?
                            <div className={`${popupData.body || popupData.image ? "border-b" : ""} bg-dark pl-6 pr-14 md:pl-8 md:pr-16 py-5 text-lg md:text-2xl font-display uppercase tracking-wide border-accent-darker`}>
                                <span className="">{popupData.title}</span>
                            </div>
                            : null
                        }
                        {popupData.image ?
                            <div className={`${popupData.body ? "border-b" : ""} border-accent-darker bg-darkest pointer-events-none relative`}>
                                <Image
                                    width={532}
                                    height={160}
                                    quality={85}
                                    priority
                                    src={popupData.image}
                                    alt={popupData.title ?? "Popup Background"}
                                    className="w-full object-contain"
                                    onLoad={(e) => { setLoadingImage(false) }}
                                />
                                <div className={`${loadingImage ? "swiper-lazy-preloader" : "hidden"}`} ></div>
                            </div>
                            : null
                        }
                        {popupData.body ?
                            <div className={`px-6 md:px-8 py-8 md:py-8 text-jacarta-200 text-md leading-6 ${!popupData.title && !popupData.image ? "pr-12 md:pr-16" : ""}`}
                                dangerouslySetInnerHTML={{ __html: popupData.body }}
                            >
                            </div>
                            : null
                        }
                        <div className='absolute rounded md:rounded-lg bg-darker border-1 border-accent-darker right-3 md:right-4 top-3 md:top-4 p-0.5 md:p-1 overflow-hidden flex items-center justify-center'>
                            <button
                                type="button"
                                className="transition-transform hover:rotate-180"
                                onClick={() => { onClose() }}
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 24 24"
                                    width="24"
                                    height="24"
                                    className="h-5 w-5 md:h-6 md:w-6 fill-white"
                                >
                                    <path fill="none" d="M0 0h24v24H0z"></path>
                                    <path d="M12 10.586l4.95-4.95 1.414 1.414-4.95 4.95 4.95 4.95-1.414 1.414-4.95-4.95-4.95 4.95-1.414-1.414 4.95-4.95-4.95-4.95L7.05 5.636z"></path>
                                </svg>
                            </button>
                        </div>
                    </div>
                </div>
                :
                null
            }
        </>
    );
}
