import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Link from "next/link";
import { faCalendarDays } from '@fortawesome/free-solid-svg-icons';
import { useDispatch, useSelector } from "react-redux";
import { ReactSession } from 'react-client-session';
import { newcomerModalShow, questsModalShow, collectToastError, joinDiscordCodeModalShow } from "../../redux/counterSlice";
import Image from "next/image";
import React, { useEffect, useState } from "react";
import EventsGrid from './events-grid';
export default function Events() {
  const { userData } = useSelector((state) => state.counter);
  const dispatch = useDispatch();
  const [isEventActive, setIsEventActive] = useState(false);

  const handleShowNewcomer = () => {
    dispatch(newcomerModalShow())
  };

  const showQuestsPopup = () => {
    if (userData?.quests) {
      dispatch(questsModalShow())
    }
  };

  useEffect(() => {
    setIsEventActive(
      userData?.quests?.every((quest) => !quest.claimed) ||
      userData?.event?.imageUrl ||
      !userData?.collectedOnboarding
    );
  }, [userData])

  return (
    <>
      <div className="js-nav-dropdown group-dropdown relative">
        <button className={`${isEventActive ? "events-btn-glow" : ""} dropdown-toggle focus:bg-accent group bg-accent-dark hover:bg-accent ml-2 flex h-10 w-10 items-center justify-center rounded-full border transition-all border-transparent eventsIconBox`}>
          <FontAwesomeIcon icon={faCalendarDays} className="inline-block eventsIcon" />
        </button>
        <div className="eventsMenu dropdown-menu dark:bg-darker border-1 border-accent group-dropdown-hover:opacity-100 group-dropdown-hover:visible !-right-4 !top-[85%] !left-auto z-10 min-w-[14rem] rounded-xl bg-white transition-all will-change-transform before:absolute before:-top-3 before:h-3 before:w-full lg:absolute lg:flex lg:flex-col lg:gap-5 lg:!translate-y-4 p-6 lg:shadow-2xl hidden lg:invisible lg:opacity-0">
          <EventsGrid />
        </div>
      </div>
    </>
  );
}
