import { createSlice } from "@reduxjs/toolkit";
import { ReactSession } from 'react-client-session';

const initialState = {
  mblMenu: false,
  dropdown: false,
  collection_activity_item_data: [],
  trendingCategoryItemData: [],
  sortedtrendingCategoryItemData: [],
  collectiondata: [],
  sortedCollectionData: [],
  tasksData: [],
  taskCompleted: false,
  renkingData: [],
  filteredRenkingData: [],
  walletModal: false,
  bidsModal: false,
  sellModal: false,
  bidCardModal: false,
  buyCardModal: false,
  loginModal: false,
  toastError: false,
  toastSuccess: false,
  insuficientChronosModal: false,
  registerModal: false,
  connectModal: false,
  continueGuestModal: false,
  gameCardsModal: false,
  matchResultModal: false,
  surrenderMatchModal: false,
  matchTutorialModal: false,
  matchHelpModal: false,
  matchMobileSurrenderModal: false,
  createDeckModal: false,
  deleteDeckModal: false,
  inmutableModal: false,
  digObjectModal: false,
  digResultModal: false,
  bundleDetailsModal: false,
  avatarModal: false,
  digCompletedModal: false,
  digCardObtained: false,
  digCancelExcavation: false,
  digCancelExcavationButton: false,
  digCancelMapExcavation: false,
  digCancelMapExcavationButton: false,
  burnCardModal: false,
  burnModal: false,
  buyModal: false,
  tutorialTextModal: false,
  joinDiscordModal: false,
  newcomerModal: false,
  questsModal: false,
  joinDiscordCodeModal: false,
  propartiesModalValue: false,
  trendingCategorySorText: "",
  userData: null,
  infoPopupData: null,
};

export const counterSlice = createSlice({
  name: "counter",
  initialState,
  reducers: {
    openMblMenu: (state) => {
      state.mblMenu = true;
    },
    closeMblMenu: (state) => {
      state.mblMenu = false;
    },

    openDropdown: (state) => {
      state.dropdown = true;
    },
    closeDropdown: (state) => {
      state.dropdown = false;
    },
    handle_collection_activity_item_data: (state, payload) => {
      state.collection_activity_item_data = payload.data;
    },
    walletModalShow: (state) => {
      state.walletModal = true;
    },
    walletModalhide: (state) => {
      state.walletModal = false;
    },
    bidsModalShow: (state) => {
      state.bidsModal = true;
    },
    bidsModalHide: (state) => {
      state.bidsModal = false;
    },
    sellModalShow: (state) => {
      state.sellModal = true;
    },
    sellModalHide: (state) => {
      state.sellModal = false;
    },
    bidCardModalShow: (state) => {
      state.bidCardModal = true;
    },
    bidCardModalHide: (state) => {
      state.bidCardModal = false;
    },
    buyCardModalShow: (state) => {
      state.buyCardModal = true;
    },
    buyCardModalHide: (state) => {
      state.buyCardModal = false;
    },
    loginModalShow: (state) => {
      state.loginModal = true;
    },
    loginModalHide: (state) => {
      state.loginModal = false;
    },
    insuficientChronosModalshow: (state) => {
      state.insuficientChronosModal = true;
    },
    insuficientChronosModalHide: (state) => {
      state.insuficientChronosModal = false;
    },
    registerModalShow: (state) => {
      state.registerModal = true;
    },
    registerModalHide: (state) => {
      state.registerModal = false;
    },
    connectModalShow: (state) => {
      state.connectModal = true;
    },
    connectModalHide: (state) => {
      state.connectModal = false;
    },
    continueGuestModalShow: (state) => {
      state.continueGuestModal = true;
    },
    continueGuestModalHide: (state) => {
      state.continueGuestModal = false;
    },
    gameCardsModalShow: (state) => {
      state.gameCardsModal = true;
    },
    gameCardsModalHide: (state) => {
      state.gameCardsModal = false;
    },
    matchResultModalShow: (state) => {
      state.matchResultModal = true;
    },
    matchResultModalHide: (state) => {
      state.matchResultModal = false;
    },
    surrenderMatchModalShow: (state) => {
      state.surrenderMatchModal = true;
    },
    surrenderMatchModalHide: (state) => {
      state.surrenderMatchModal = false;
    },
    matchMobileSurrenderModalShow: (state) => {
      state.matchMobileSurrenderModal = true;
    },
    matchMobileSurrenderModalHide: (state) => {
      state.matchMobileSurrenderModal = false;
    },
    matchTutorialModalShow: (state) => {
      state.matchTutorialModal = true;
    },
    matchTutorialModalHide: (state) => {
      state.matchTutorialModal = false;
    },
    matchHelpModalShow: (state) => {
      state.matchHelpModal = true;
    },
    matchHelpModalHide: (state) => {
      state.matchHelpModal = false;
    },
    createDeckModalShow: (state) => {
      state.createDeckModal = true;
    },
    createDeckModalHide: (state) => {
      state.createDeckModal = false;
    },
    deleteDeckModalShow: (state) => {
      state.deleteDeckModal = true;
    },
    deleteDeckModalHide: (state) => {
      state.deleteDeckModal = false;
    },
    inmutableModalShow: (state) => {
      state.inmutableModal = true;
    },
    inmutableModalHide: (state) => {
      state.inmutableModal = false;
    },
    digObjectModalShow: (state) => {
      state.digObjectModal = true;
    },
    digObjectModalHide: (state) => {
      state.digObjectModal = false;
    },
    digResultModalShow: (state) => {
      state.digResultModal = true;
    },
    digResultModalHide: (state) => {
      state.digResultModal = false;
    },
    bundleDetailsModalShow: (state) => {
      state.bundleDetailsModal = true;
    },
    bundleDetailsModalHide: (state) => {
      state.bundleDetailsModal = false;
    },
    avatarModalShow: (state) => {
      state.avatarModal = true;
    },
    avatarModalHide: (state) => {
      state.avatarModal = false;
    },
    digCompletedModalShow: (state) => {
      state.digCompletedModal = true;
    },
    digCompletedModalHide: (state) => {
      state.digCompletedModal = false;
    },
    digCardObtainedShow: (state) => {
      state.digCardObtained = true;
    },
    digCardObtainedHide: (state) => {
      state.digCardObtained = false;
    },
    digCancelExcavationShow: (state) => {
      state.digCancelExcavation = true;
    },
    digCancelExcavationHide: (state) => {
      state.digCancelExcavation = false;
    },
    digCancelExcavationButtonShow: (state) => {
      state.digCancelExcavationButton = true;
    },
    digCancelExcavationButtonHide: (state) => {
      state.digCancelExcavationButton = false;
    },
    digCancelMapExcavationShow: (state) => {
      state.digCancelMapExcavation = true;
    },
    digCancelMapExcavationHide: (state) => {
      state.digCancelMapExcavation = false;
    },
    digCancelMapExcavationButtonShow: (state) => {
      state.digCancelMapExcavationButton = true;
    },
    digCancelMapExcavationButtonHide: (state) => {
      state.digCancelMapExcavationButton = false;
    },
    burnCardModalShow: (state) => {
      state.burnCardModal = true;
    },
    burnCardModalHide: (state) => {
      state.burnCardModal = false;
    },
    burnModalShow: (state) => {
      state.burnModal = true;
    },
    burnModalHide: (state) => {
      state.burnModal = false;
    },
    buyModalShow: (state) => {
      state.buyModal = true;
    },
    buyModalHide: (state) => {
      state.buyModal = false;
    },
    tutorialTextModalShow: (state) => {
      state.tutorialTextModal = true;
    },
    tutorialTextModalHide: (state) => {
      state.tutorialTextModal = false;
    },
    joinDiscordModalShow: (state) => {
      state.joinDiscordModal = true;
    },
    joinDiscordModalHide: (state) => {
      state.joinDiscordModal = false;
    },
    newcomerModalShow: (state) => {
      state.newcomerModal = true;
    },
    newcomerModalHide: (state) => {
      state.newcomerModal = false;
    },
    questsModalShow: (state) => {
      state.questsModal = true;
    },
    questsModalHide: (state) => {
      state.questsModal = false;
    },
    joinDiscordCodeModalShow: (state) => {
      state.joinDiscordCodeModal = true;
    },
    joinDiscordCodeModalHide: (state) => {
      state.joinDiscordCodeModal = false;
    },
    showPropatiesModal: (state) => {
      state.propartiesModalValue = true;
    },
    closePropatiesModal: (state) => {
      state.propartiesModalValue = false;
    },
    updateTrendingCategoryItemData: (state, action) => {
      state.trendingCategoryItemData = action.payload;
      state.sortedtrendingCategoryItemData = action.payload;
    },
    updatetrendingCategorySorText: (state, action) => {
      const sortText = action.payload;
      if (sortText === "Price: Low to High") {
        state.sortedtrendingCategoryItemData =
          state.trendingCategoryItemData.sort(
            (a, b) => a.sortPrice - b.sortPrice
          );
      } else if (sortText === "Price: high to low") {
        state.sortedtrendingCategoryItemData =
          state.trendingCategoryItemData.sort(
            (a, b) => b.sortPrice - a.sortPrice
          );
      } else if (sortText === "Recently Added") {
        state.sortedtrendingCategoryItemData =
          state.trendingCategoryItemData.sort((a, b) => a.addDate - b.addDate);
      } else if (sortText === "Auction Ending Soon") {
        state.sortedtrendingCategoryItemData =
          state.trendingCategoryItemData.sort((a, b) => b.addDate - a.addDate);
      } else {
        state.sortedtrendingCategoryItemData = state.trendingCategoryItemData;
      }
    },
    updateTrendingCategoryItemByInput: (state, action) => {
      const text = action.payload;
      if (text === "Verified Only") {
        state.sortedtrendingCategoryItemData =
          state.trendingCategoryItemData.filter((item) => {
            return item.verified;
          });
      } else if (text === "NFSW Only") {
        state.sortedtrendingCategoryItemData =
          state.trendingCategoryItemData.filter((item) => {
            return item.nfsw;
          });
      } else if (text === "Show Lazy Minted") {
        state.sortedtrendingCategoryItemData =
          state.trendingCategoryItemData.filter((item) => {
            return item.lazyMinted;
          });
      } else {
        state.sortedtrendingCategoryItemData = state.trendingCategoryItemData;
      }
    },
    collectCollectionData: (state, action) => {
      const data = action.payload;
      state.collectiondata = data;
      state.sortedCollectionData = data;
    },
    updateCollectionData: (state, action) => {
      const text = action.payload;
      if (text === "trending") {
        const tampItem = state.collectiondata.filter((item) => item.trending);
        state.sortedCollectionData = tampItem;
      }
      if (text === "top") {
        const tampItem = state.collectiondata.filter((item) => item.top);
        state.sortedCollectionData = tampItem;
      }
      if (text === "recent") {
        const tampItem = state.collectiondata.filter((item) => item.recent);
        state.sortedCollectionData = tampItem;
      }
      // state.sortedCollectionData = state.collectiondata;
    },
    collectTasksData: (state, action) => {
      const data = action.payload;
      state.tasksData = data;
    },
    updateTasksData: (state, action) => {
      const identifier = action.payload;
      ReactSession.setStoreType("localStorage");
      const tasks = ReactSession.get("tasks");
      const user = ReactSession.get("user");
      if (user && !user.collectedOnboarding &&
        tasks.length &&
        tasks.filter((item) => item?.task?.identifier === identifier && !item.completed).length) {
        const newTasks = []
        for (let task of tasks) {
          if (task?.task?.identifier === identifier) {
            task.completed = true;
            state.taskCompleted = true;
          }
          newTasks.push(task)
        }
        ReactSession.set("tasks", newTasks);
        state.tasksData = newTasks;
      }
    },
    updateTaskCompleted: (state, action) => {
      state.taskCompleted = action.payload;
    },
    collectToastError: (state, action) => {
      state.toastError = action.payload;
    },
    collectToastSuccess: (state, action) => {
      state.toastSuccess = action.payload;
    },
    collectUserData: (state, action) => {
      state.userData = action.payload;
    },
    collectRenkingData: (state, action) => {
      state.renkingData = action.payload;
      state.filteredRenkingData = action.payload;
    },
    updateRenkingData: (state, action) => {
      const text = action.payload;
      let tempItem = state.renkingData.filter((item) => item.category === text);
      if (text === "All") {
        tempItem = state.renkingData;
      }
      state.filteredRenkingData = tempItem;
    },
    updateRenkingDataByBlockchain: (state, action) => {
      const text = action.payload;
      let tempItem = state.renkingData.filter(
        (item) => item.blockchain === text
      );
      if (text === "All") {
        tempItem = state.renkingData;
      }
      state.filteredRenkingData = tempItem;
    },
    updateRenkingDataByPostdate: (state, action) => {
      const text = action.payload;
      let tempItem = state.renkingData.filter((item) => item.postDate === text);
      if (text === "All Time" || text === "Last Year") {
        tempItem = state.renkingData;
      }
      state.filteredRenkingData = tempItem;
    },
    fillInfoPopupData: (state, action) => {
      state.infoPopupData = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  openMblMenu,
  closeMblMenu,
  openDropdown,
  closeDropdown,
  walletModalShow,
  walletModalhide,
  bidsModalShow,
  bidsModalHide,
  sellModalShow,
  sellModalHide,
  bidCardModalShow,
  bidCardModalHide,
  buyCardModalShow,
  buyCardModalHide,
  loginModalShow,
  loginModalHide,
  insuficientChronosModalshow,
  insuficientChronosModalHide,
  registerModalShow,
  registerModalHide,
  connectModalShow,
  connectModalHide,
  continueGuestModalShow,
  continueGuestModalHide,
  gameCardsModalShow,
  gameCardsModalHide,
  matchResultModalShow,
  matchResultModalHide,
  surrenderMatchModalShow,
  surrenderMatchModalHide,
  matchMobileSurrenderModalShow,
  matchMobileSurrenderModalHide,
  matchTutorialModalShow,
  matchTutorialModalHide,
  matchHelpModalShow,
  matchHelpModalHide,
  createDeckModalShow,
  createDeckModalHide,
  deleteDeckModalShow,
  deleteDeckModalHide,
  inmutableModalShow,
  inmutableModalHide,
  digObjectModalShow,
  digObjectModalHide,
  digResultModalShow,
  digResultModalHide,
  bundleDetailsModalShow,
  bundleDetailsModalHide,
  avatarModalShow,
  avatarModalHide,
  digCompletedModalShow,
  digCompletedModalHide,
  digCardObtainedShow,
  digCardObtainedHide,
  digCancelExcavationShow,
  digCancelExcavationHide,
  digCancelExcavationButtonShow,
  digCancelExcavationButtonHide,
  digCancelMapExcavationShow,
  digCancelMapExcavationButtonShow,
  digCancelMapExcavationButtonHide,
  digCancelMapExcavationHide,
  burnCardModalShow,
  burnCardModalHide,
  burnModalShow,
  burnModalHide,
  buyModalShow,
  buyModalHide,
  tutorialTextModalShow,
  tutorialTextModalHide,
  joinDiscordModalShow,
  joinDiscordModalHide,
  newcomerModalShow,
  newcomerModalHide,
  questsModalShow,
  questsModalHide,
  joinDiscordCodeModalShow,
  joinDiscordCodeModalHide,
  showPropatiesModal,
  closePropatiesModal,
  updatetrendingCategorySorText,
  updateTrendingCategoryItemData,
  updateTrendingCategoryItemByInput,
  collectCollectionData,
  updateCollectionData,
  collectTasksData,
  updateTasksData,
  collectUserData,
  updateTaskCompleted,
  collectToastError,
  collectToastSuccess,
  collectRenkingData,
  updateRenkingData,
  updateRenkingDataByBlockchain,
  updateRenkingDataByPostdate,
  fillInfoPopupData,
} = counterSlice.actions;

export default counterSlice.reducer;
