import { ReactSession } from 'react-client-session';
import React, { useEffect, useState } from "react";
import Image from "next/image";
import { getRankFrame, getRankBadge } from '/utils/utils.js';
import { maintenance } from "/utils/maintenance";
import { useDispatch } from "react-redux";
import { fillInfoPopupData } from "../../redux/counterSlice";
import { setInfoPopupCloseAction } from '../info-popup/info-popup';

const CreateMatchManager = ({ cancelAction }) => {
    const dispatch = useDispatch();
    const [cancelMatch, setCancelMatch] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);
    const [matchFound, setMatchFound] = useState(false);
    const [showMatchIntro, setShowMatchIntro] = useState(false);
    const [isNewMatch, setIsNewMatch] = useState(false);
    const [players, setPlayers] = useState([]);
    var stopTimeOut = false;

    function closeAction() {
        setErrorMessage(null);
        cancelAction && cancelAction();
    }

    useEffect(() => {
        if (errorMessage) {
            dispatch(fillInfoPopupData({
                title: errorMessage[0],
                body: errorMessage[1],
            }));
            setInfoPopupCloseAction(closeAction);
        }
    }, [errorMessage]);

    useEffect(() => {
        setTimeout(() => {
            if (stopTimeOut) {
                cancelAction && cancelAction();
            }
            else {
                fetch(`${process.env.NEXT_PUBLIC_API_ROUTE}/api/game/createMatch/`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
                        'Authorization': `Token ${ReactSession.get("token")}`,
                    },
                })
                    .then(res => res.json())
                    .then(response => {
                        maintenance(response)
                        if (response.success) {
                            setMatchFound(true);
                            ReactSession.set("match", response.data);
                            setPlayers(response.data?.players);
                            setIsNewMatch(response.data?.newMatch);
                            setTimeout(() => {
                                setShowMatchIntro(response.data?.newMatch);
                                setTimeout(() => {
                                    window.location.replace("/match");
                                }, response.data?.newMatch ? 3000 : 50);
                            }, 2000);
                        }
                        else if (response.data.error) {
                            setCancelMatch(true)
                            stopTimeOut = true;
                            if (response.data.error.includes("[") && response.data.error.includes("]")) {
                                const tempStr = response.data.error.replaceAll("[", "").split("]")
                                setErrorMessage(tempStr);
                            }
                            else {
                                setErrorMessage(["Error!", "Oops, it looks like there was an error while receiving data."]);
                            }
                        }
                    });
            }
        }, 2000);
    }, [])

    return (
        <div className="modal fade show block backdrop-blur flex flex-col gap-6 !items-center !justify-center px-8">
            {!showMatchIntro ?
                <>
                    <img width={200} height={200} className="m-auto my-0" src="/svg/spinner.svg" alt="Loading Spinner" />
                    <div className='min-h-[6rem] text-center my-0 text-lg text-jacarta-300 mb-6 leading-8'>
                        {matchFound ?
                            <>
                                <span className='text-3xl text-white uppercase font-semibold'>{isNewMatch ? "New Match Found!" : "Resuming..."}</span><br />
                                {isNewMatch ?
                                    <span>Loading and redirecting...</span> :
                                    <span>You have a match currently in-progress</span>}
                            </>
                            :
                            <>
                                <span className='text-3xl text-white uppercase font-semibold'>
                                    {cancelMatch ? "Cancelling..." : "Matching"}
                                </span><br />
                                {cancelMatch ? "Please Wait" : "Looking for a player’s deck within your rank"}
                            </>
                        }
                    </div>
                    <div className='h-12'>
                        {matchFound ?
                            null
                            :
                            <button className={`${cancelMatch ? "pointer-events-none shadow-none bg-darkest opacity-75 text-jacarta-600 border-jacarta-600" : "bg-white text-black"} m-auto my-0 text-center text-lg font-semibold border rounded-full w-56 h-10 px-4`}
                                onClick={
                                    () => {
                                        setCancelMatch(true);
                                        stopTimeOut = true;
                                    }
                                }
                            >
                                Cancel
                            </button>
                        }
                    </div>
                </>
                :
                <>
                    {/* MATCH INTRO */}
                    < div className='absolute inset-0 z-[200] flex flex-col items-center justify-center backdrop-blur'>
                        <Image
                            src={"/images/matchmaking-screen/matchmaking-overlay.webp"}
                            width={1920}
                            height={1080}
                            alt='Battle Detail Screen'
                            className='absolute inset-0 w-full h-full object-cover opacity-50'
                        />
                        <div className='relative w-full lg:max-w-[1536px] h-[95%] lg:h-auto text-white flex items-center lg:items-stretch justify-center lg:justify-between px-4 pt-24 lg:px-8 lg:py-16 lg:gap-16'>
                            <div className='relative mb-[55vh] -mr-48 lg:m-0 py-8 w-full uppercase text-left flex flex-row-reverse gap-6'>
                                <Image width={214}
                                    height={250}
                                    src={getRankBadge(players[1]?.user?.playerRankName)}
                                    className="absolute w-64 left-12 -top-20 lg:w-80 lg:-top-32 opacity-50"
                                    alt="Player 1 Rank Badge"
                                />
                                <div className='relative grow flex flex-col justify-center gap-1.5 font-medium text-stroke'>
                                    <div className='relative text-3xl lg:text-5xl tracking-wider'>
                                        <div className='matchmaking-trapezoid absolute inset-0 -left-[150vw] opacity-75 min-w-[9em]'></div>
                                        <span className='relative '>{players[1]?.user?.uname}</span>
                                    </div>
                                    <span className='text-lg lg:text-2xl text-accent-light tracking-wide'>{players[1]?.user?.playerRankName}</span>
                                    <span className='text-lg lg:text-2xl text-accent-light tracking-wide'>{players[1]?.user?.playerRank}</span>
                                </div>
                                <div className="relative avatar-box !rounded-2xl w-[36vw] h-[36vw] lg:w-[18vw] lg:h-[18vw] min-w-[120px] min-h-[120px] lg:max-w-[256px] lg:max-h-[256px] flex items-center justify-center">
                                    <Image
                                        width={256}
                                        height={256}
                                        src={players[1]?.user?.avatarUrl ? players[1]?.user?.avatarUrl : ('/images/avatar_options/Avatar_0.jpg')}
                                        className="border-jacarta-400 border-[6px] w-full h-full object-contain pointer-events-none"
                                        alt="Player 1 Avatar"
                                    />
                                    {players[1]?.user?.playerRankName ? (
                                        <Image
                                            width={256}
                                            height={256}
                                            src={getRankFrame(players[1]?.user?.playerRankName)}
                                            className="avatarFrame pointer-events-none"
                                            alt="Player 1 Avatar Frame"
                                        />
                                    ) : null}
                                </div>
                            </div>
                            <div className='absolute inset-0 flex items-center justify-center lg:p-0 lg:relative lg:-translate-y-24 lg:my-auto lg:m-0 lg:w-[116px] lg:min-w-[116px]'>
                                <Image
                                    src={"/images/matchmaking-screen/vs.png"}
                                    width={116}
                                    height={116}
                                    alt='Versus'
                                    className='relative w-20 lg:w-full object-contain'
                                />
                            </div>
                            <div className='relative mt-[55vh] -ml-48  lg:m-0 py-8 w-full uppercase text-left flex flex-row gap-6'>
                                <Image width={214}
                                    height={250}
                                    src={getRankBadge(players[0]?.user?.playerRankName)}
                                    className="absolute w-64 right-12 -top-20 lg:w-80 lg:-top-32 opacity-50"
                                    alt="Player 1 Rank Badge"
                                />
                                <div className='relative grow flex flex-col justify-center gap-1.5 font-medium text-right text-stroke'>
                                    <div className='relative text-3xl lg:text-5xl tracking-wider'>
                                        <div className='matchmaking-trapezoid absolute inset-0 -left-4 lg:-left-24 -right-[150vw]  opacity-75 min-w-[9em]'></div>
                                        <span className='relative '>{players[0]?.user?.uname}</span>
                                    </div>
                                    <span className='text-lg lg:text-2xl text-accent-light tracking-wide'>{players[0]?.user?.playerRankName}</span>
                                    <span className='text-lg lg:text-2xl text-accent-light tracking-wide'>{players[0]?.user?.playerRank}</span>
                                </div>
                                <div className="relative avatar-box !rounded-2xl w-[36vw] h-[36vw] lg:w-[18vw] min-w-[120px] min-h-[120px] lg:h-[18vw] lg:max-w-[256px] lg:max-h-[256px] flex items-center justify-center">
                                    <Image
                                        width={256}
                                        height={256}
                                        src={players[0]?.user?.avatarUrl ? players[0]?.user?.avatarUrl : ('/images/avatar_options/Avatar_0.jpg')}
                                        className="border-jacarta-400 border-[6px] w-full h-full object-contain pointer-events-none"
                                        alt="Player 1 Avatar"
                                    />
                                    {players[0]?.user?.playerRankName ? (
                                        <Image
                                            width={256}
                                            height={256}
                                            src={getRankFrame(players[0]?.user?.playerRankName)}
                                            className="avatarFrame pointer-events-none"
                                            alt="Player 1 Avatar Frame"
                                        />
                                    ) : null}
                                </div>
                            </div>

                        </div>
                        <img width={100} height={100} className="absolute right-8 bottom-6 lg:right-12 lg:bottom-8 w-[64px] h-[64px] lg:w-[100px] lg:h-[100px]" src="/svg/spinner.svg" alt="Loading Spinner" />
                    </div>
                    {/* END MATCH INTRO */}
                </>
            }
        </div >
    );
};

export default CreateMatchManager;