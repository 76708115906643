import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { signIn } from 'next-auth/react';
import { registerModalHide, loginModalShow, collectToastSuccess, collectUserData, continueGuestModalShow } from "../../redux/counterSlice";
import { ReactSession } from 'react-client-session';
// import TiktokPixel from 'tiktok-pixel';

const RegisterModal = () => {
  ReactSession.setStoreType("localStorage");
  const { registerModal } = useSelector((state) => state.counter);
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");
  const [username, setUsername] = useState("");
  const [errrorMessage, setErrrorMessage] = useState("");
  const [userErrrorMessage, setUserErrrorMessage] = useState("");
  const [sendLnkText, setSendLnkText] = useState("Sign Up with Email");
  const [disabled, setDisabled] = useState(false);

  const handleEEmail = (e) => {
    e.preventDefault();
    setErrrorMessage("")
    setEmail(e.target.value);
  };

  const handleUsername = (e) => {
    e.preventDefault();
    setUserErrrorMessage("")
    if (e.target.value.length <= 12) {
      setUsername(e.target.value);
    }
  };

  const handleClose = () => {
    setSendLnkText("Sign Up with Email")
    setDisabled(false)
    setEmail("")
    setUsername("")
    setErrrorMessage("")
    dispatch(registerModalHide())
  };

  const handleLogin = () => {
    handleClose()
    dispatch(loginModalShow())
  };

  function registerAsGuest() {
    handleClose()
    dispatch(continueGuestModalShow());
  }

  function sendRegisterLink() {
    const requestBody = {
      email: email,
      username: username
    };
    // const queryString = window.location.search;
    // const urlParams = new URLSearchParams(queryString);
    const utm_source = ReactSession.get('utm_source')
    const utm_medium = ReactSession.get('utm_medium')
    const utm_campaign = ReactSession.get('utm_campaign')
    const utm_term = ReactSession.get('utm_term')
    const utm_content = ReactSession.get('utm_content')
    if (utm_source) {
      requestBody['utm_source'] = utm_source;
    }
    if (utm_medium) {
      requestBody['utm_medium'] = utm_medium;
    }
    if (utm_campaign) {
      requestBody['utm_campaign'] = utm_campaign;
    }
    if (utm_term) {
      requestBody['utm_term'] = utm_term;
    }
    if (utm_content) {
      requestBody['utm_content'] = utm_content;
    }
    const regex = /^([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
    const usernameRegex = /^[a-zA-Z0-9_]*$/;
    if (email && email.length && regex.test(email) && username && username.length >= 2 && username.length <= 12 && usernameRegex.test(username)) {
      setDisabled(true)
      fetch(`${process.env.NEXT_PUBLIC_API_ROUTE}/api/users/register/`, {
        method: 'POST',
        headers: {
          "content-type": "application/json"
        },
        body: JSON.stringify(requestBody)
      })
        .then(res => res.json())
        .then(response => {
          if (response.success) {
            ReactSession.set('utm_source', null)
            ReactSession.set('utm_medium', null)
            ReactSession.set('utm_campaign', null)
            ReactSession.set('utm_term', null)
            ReactSession.set('utm_content', null)
            dispatch(collectToastSuccess("Registration link sent! Check your email to finish registering"));
            handleClose()
            if (process.env.NEXT_PUBLIC_IS_PROD === "true") {
              window.dataLayer = window.dataLayer || [];
              window.dataLayer.push({
                event: 'SignUp'
              })
              // TiktokPixel.track('CompleteRegistration', {});
            }
          } else {
            let error = "Invalid Email"
            if (response?.data?.error) {
              error = response.data.error;
            }
            if (error.toLowerCase().includes("account exists")) {
              error = "This email is already in use."
            }
            setErrrorMessage(error)
            // setSendLnkText("Send me a link")
          }
        });
    } else if (username.length < 2 || username.length > 12 || !usernameRegex.test(username)) {
      setErrrorMessage("Usernames must have between 2 and 12 characters")
      // setSendLnkText("Send me a link")
    }
  }

  return (
    <div>
      <div className={registerModal ? "modal fade show block" : "modal fade"}>
        <div className="modal-dialog max-w-\[90\.375rem\]">
          <div className="modal-content">
            <div className="modal-header loginHeader">
              <h5 className="modal-title" id="placeBidLabel">
                Create an Account
              </h5>
              <button
                type="button"
                className="btn-close"
                onClick={() => handleClose()}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="24"
                  height="24"
                  className="fill-jacarta-700 h-6 w-6 dark:fill-white"
                >
                  <path fill="none" d="M0 0h24v24H0z"></path>
                  <path d="M12 10.586l4.95-4.95 1.414 1.414-4.95 4.95 4.95 4.95-1.414 1.414-4.95-4.95-4.95 4.95-1.414-1.414 4.95-4.95-4.95-4.95L7.05 5.636z"></path>
                </svg>
              </button>
            </div>

            {/* <!-- Body --> */}
            <div className="modal-body p-6">
              <div className="mb-2">
                <input
                  type="text"
                  className="h-12 w-full"
                  placeholder="Enter a username"
                  value={username}
                  onChange={(e) => handleUsername(e)}
                />
                <div className="text-right">
                  {userErrrorMessage ? (
                    <span className="dark:text-jacarta-400 text-sm" style={{ color: "#c92c2c" }}>
                      {userErrrorMessage}
                    </span>
                  ) : null}
                </div>
              </div>
              <div>
                <input
                  type="email"
                  className="h-12 w-full"
                  placeholder="Enter an email"
                  value={email}
                  onChange={(e) => handleEEmail(e)}
                />
                <div className="text-right">
                  {errrorMessage ? (
                    <span className="dark:text-jacarta-400 text-sm" style={{ color: "#c92c2c" }}>
                      {errrorMessage}
                    </span>
                  ) : null}
                </div>
              </div>

              {/* <!-- Terms --> */}
              <div className="mt-4 flex items-center space-x-2">
                <label
                  htmlFor="terms"
                  className="dark:text-jacarta-200 font-semibold text-sm"
                >
                  Have an account? {" "}
                  <a href="javascript:void(0)" onClick={() => handleLogin()} className="text-accent">
                    Login
                  </a>
                </label>
              </div>
            </div>
            {/* <!-- end body --> */}
            <div className="modal-footer" style={{ borderTop: "0", paddingTop: "0" }}>
              <div className="flex items-center justify-center space-x-4">
                {disabled ? (
                  <button
                    disabled={disabled}
                    type="button"
                    className="loginButton text-accent shadow-white-volume rounded-full bg-white py-3 px-8 text-center font-semibold transition-all"
                  >
                    {sendLnkText}
                  </button>
                ) : (
                  <button
                    onClick={() => sendRegisterLink()}
                    disabled={disabled}
                    type="button"
                    className="loginButton bg-accent shadow-accent-volume hover:bg-accent-dark rounded-full py-3 px-8 text-center font-semibold text-white transition-all"
                  >
                    {sendLnkText}
                  </button>
                )}
              </div>
            </div>
            <div className="modal-footer loginFooter">
              <div className="separation">or</div>
              <div className="flex items-center justify-center space-x-4">
                <button
                  className="loginButton py-3 px-8 text-center font-semibold text-white transition-all bgGoogleSignup"
                  onClick={() => signIn('google')}>
                </button>
              </div>
            </div>
            <div className="mb-8">
              <div className="flex items-center justify-center space-x-4">
                <button
                  className="loginButton text-accent shadow-white-volume rounded-full bg-white py-3 px-8 text-center font-semibold transition-all"
                  onClick={() => registerAsGuest()}>
                  <span>Continue as Guest</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RegisterModal;
