const ProgressBar = ({ value, label, className }) => {
    return (
        <>
            {/* start Progress */}
            <div className={`w-full relative rounded-full bg-darkest border-1 border-accent overflow-hidden ${className ?? ""}`}>
                <div className="h-8 bg-accent" style={{ width: value.toString() + "%" }} />
                <div className="absolute px-4 inset-0 flex justify-center align-center dark:text-white">
                    {/* <span className="my-auto italic font-semibold text-jacarta-800 uppercase">{user.rankValues.playerRankName}</span> */}
                    <span className="my-auto font-semibold text-sm">{label}</span>
                    {/* <span className="my-auto italic font-semibold text-jacarta-800 uppercase">{user.rankValues.nextPlayerRankName}</span> */}
                </div>
            </div>
            {/* End progress */}

        </>
    );
};

export default ProgressBar;