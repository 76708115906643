import { Link } from '@imtbl/imx-sdk'
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { continueGuestModalHide, digObjectModalShow, registerModalShow, collectUserData } from "../../redux/counterSlice";
import { ReactSession } from 'react-client-session';
// import TiktokPixel from 'tiktok-pixel';

const ContinueGuestModal = () => {
  ReactSession.setStoreType("localStorage");
  // const [sendToast, setSendToast] = useState("");
  const { continueGuestModal } = useSelector((state) => state.counter);
  const dispatch = useDispatch();
  const [disabled, setDisabled] = useState(false);

  const handleClose = () => {
    dispatch(continueGuestModalHide())
    dispatch(registerModalShow())
  };
  
  function registerAsGuest() {
    const requestBody = {};
    // const queryString = window.location.search;
    // const urlParams = new URLSearchParams(queryString);
    const utm_source = ReactSession.get('utm_source')
    const utm_medium = ReactSession.get('utm_medium')
    const utm_campaign = ReactSession.get('utm_campaign')
    const utm_term = ReactSession.get('utm_term')
    const utm_content = ReactSession.get('utm_content')
    if (utm_source) {
      requestBody['utm_source'] = utm_source;
    }
    if (utm_medium) {
      requestBody['utm_medium'] = utm_medium;
    }
    if (utm_campaign) {
      requestBody['utm_campaign'] = utm_campaign;
    }
    if (utm_term) {
      requestBody['utm_term'] = utm_term;
    }
    if (utm_content) {
      requestBody['utm_content'] = utm_content;
    }
    let formBody = [];
    for (var property in requestBody) {
      var encodedKey = encodeURIComponent(property);
      var encodedValue = encodeURIComponent(requestBody[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");
    const regex = /^([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
    const usernameRegex = /^[a-zA-Z0-9_]*$/;
    if (!disabled) {
      setDisabled(true)
      fetch(`${process.env.NEXT_PUBLIC_API_ROUTE}/api/users/createGuest/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
        },
        body: formBody
      })
        .then(res => res.json())
        .then(response => {
            if (response.success) {
              ReactSession.set('utm_source', null)
              ReactSession.set('utm_medium', null)
              ReactSession.set('utm_campaign', null)
              ReactSession.set('utm_term', null)
              ReactSession.set('utm_content', null)
              dispatch(continueGuestModalHide())
              ReactSession.set("user", response.data.user);
              ReactSession.set("token", response.data.token);
              dispatch(collectUserData(response.data.user));
              if (process.env.NEXT_PUBLIC_IS_PROD === "true") {
                window.dataLayer = window.dataLayer || [];
                window.dataLayer.push({
                  event: 'SignUp'
                })
                // TiktokPixel.track('CompleteRegistration', {});
              }
              setTimeout(() => {
                window.location.href = `/play`
              }, 200);
            }
        });
    }
  }

  return (
    <div>
      <div className={continueGuestModal ? "modal fade show block" : "modal fade"}>
        <div className="modal-dialog max-w-\[90\.375rem\]">
          <div className="modal-content">
            <div className="modal-header loginHeader">
              <h5 className="modal-title" id="placeBidLabel">
                Continue as Guest?
              </h5>
              <button
                type="button"
                className="btn-close"
                onClick={() => dispatch(continueGuestModalHide())}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="24"
                  height="24"
                  className="fill-jacarta-700 h-6 w-6 dark:fill-white"
                >
                  <path fill="none" d="M0 0h24v24H0z"></path>
                  <path d="M12 10.586l4.95-4.95 1.414 1.414-4.95 4.95 4.95 4.95-1.414 1.414-4.95-4.95-4.95 4.95-1.414-1.414 4.95-4.95-4.95-4.95L7.05 5.636z"></path>
                </svg>
              </button>
            </div>

            {/* <!-- Body --> */}
            <div className="modal-body pb-6 pl-6 pr-6">
              <div className="mb-2 text-left">
                <div className="text-jacarta-700 text-lg dark:text-white" style={{ fontWeight: "200" }}>
                  If you continue as guest, your access to the game will be saved locally using cookies, but <span style={{ fontWeight: 600 }}>keep in mind that it cannot be recovered if lost.</span> You always have the option to register your account later.
                </div>
              </div>
            </div>
            {/* <!-- end body --> */}

            <div className="modal-footer">
              <div className="flex items-center justify-center space-x-4 mr-3">
                <button
                  onClick={() => registerAsGuest()}
                  disabled={disabled}
                  type="button"
                  className="bg-accent shadow-accent-volume hover:bg-accent-dark rounded-full py-3 px-8 text-center font-semibold text-white transition-all"
                >
                  Play as Guest
                </button>
              </div>
              <div className="flex items-center justify-center space-x-4 ml-2">
                <button
                  onClick={() => handleClose()}
                  type="button"
                  className="text-accent shadow-white-volume rounded-full bg-white py-3 px-8 text-center font-semibold transition-all"
                >
                  Sign Up
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContinueGuestModal;
