import Head from "next/head";
import React, { useEffect, useState } from "react";


const Meta = ({ title, keyword, desc, image }) => {
  const customTitle = `${title} | Chronogram`;

  return (
    <div>
      <Head>
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <title>{customTitle}</title>
        <link rel="icon" href="/favicon.ico" />
        <meta content={customTitle} name="title" />
        <meta name="description" content={desc} />
        <meta name="keyword" content={keyword} />
        
        <meta content={image} property="og:image" />
        <meta content={desc} property="og:description" />
        <meta content={customTitle} property="og:title" />
        <meta property="og:type" content="website" />

        <meta content={customTitle} property="twitter:title" />
        <meta content={desc} property="twitter:description" />
        <meta content={image} property="twitter:image" />
        <meta content="summary_large_image" name="twitter:card" />

        { process.env.NEXT_PUBLIC_IS_PROD === "true" ? (
          <script id="google-tag-manager" strategy="afterInteractive">
            {`
              (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
              new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
              j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
              'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
              })(window,document,'script','dataLayer','${process.env.NEXT_PUBLIC_GTM_ID}');
            `}
          </script>
        ) : null }
      </Head>
    </div>
  );
};

Meta.defaultProps = {
  title: "Home | Chronogram",
  keyword:
    "bitcoin, blockchain, crypto, crypto collectibles, crypto makretplace, cryptocurrency, digital items, market, nft, nft marketplace, nft next js, NFT react, non-fungible tokens, virtual asset, wallet",
  desc: "Chronogram is a free-to-play idle TCG where you grow your collection of cards by excavating ancient sites. Discover, collect, and trade 260+ rare and unique cards with epic artwork inspired by ancient mythology. No NFTs, no blockchain!",
  image: "/chronogram.png",
};

export default Meta;
