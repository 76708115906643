import React, { useState, useEffect } from "react";
import Link from "next/link";
import { useSelector, useDispatch } from "react-redux";
import { joinDiscordCodeModalHide, collectToastError, collectToastSuccess, collectUserData } from "../../redux/counterSlice";
import { ReactSession } from 'react-client-session';

const JoinDiscordCodeModal = () => {
  const { joinDiscordCodeModal } = useSelector((state) => state.counter);
  const dispatch = useDispatch();
  ReactSession.setStoreType("localStorage");
  const [discordCode, setDiscordCode] = useState("");

  const handleDiscordCode = (e) => {
    e.preventDefault();
    setDiscordCode(e.target.value);
  };

  function confimSalvage() {
    if (discordCode && discordCode.length) {
      const details = {
          code: discordCode,
      };
      let formBody = [];
      for (var property in details) {
        var encodedKey = encodeURIComponent(property);
        var encodedValue = encodeURIComponent(details[property]);
        formBody.push(encodedKey + "=" + encodedValue);
      }
      formBody = formBody.join("&");
      fetch(`${process.env.NEXT_PUBLIC_API_ROUTE}/api/users/claimDiscordCode/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
          "Authorization": `Token ${ReactSession.get("token")}`,
        },
        body: formBody
      })
        .then(res => res.json())
        .then(response => {
            if (response.success) {
              dispatch(joinDiscordCodeModalHide())
              const tempUser = ReactSession.get("user");
              tempUser.chronos += response?.data?.chronos || 0;
              tempUser.canClaimDiscordCode = false;
              ReactSession.set("user", tempUser);
              dispatch(collectUserData(tempUser));
              dispatch(collectToastSuccess("Congratulations! Your code has been verified. 200 Chronos were added to your account."))
            } else {
              dispatch(collectToastError("The code you entered is incorrect. Please try again."))
            }
        });
    }
  }


  return (
    <div>
      <div className={joinDiscordCodeModal ? "modal fade show block" : "modal fade"}>
        <div className="modal-dialog max-w-2xl">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="placeBidLabel">
                Join Us on Discord!
              </h5>
              <button
                type="button"
                className="btn-close"
                onClick={() => dispatch(joinDiscordCodeModalHide())}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="24"
                  height="24"
                  className="fill-jacarta-700 h-6 w-6 dark:fill-white"
                >
                  <path fill="none" d="M0 0h24v24H0z"></path>
                  <path d="M12 10.586l4.95-4.95 1.414 1.414-4.95 4.95 4.95 4.95-1.414 1.414-4.95-4.95-4.95 4.95-1.414-1.414 4.95-4.95-4.95-4.95L7.05 5.636z"></path>
                </svg>
              </button>
            </div>

            {/* <!-- Body --> */}
            <div className="modal-body p-6">
              <div className="mb-2 items-center justify-between">
                <div className="mb-5">
                  <div className="text-jacarta-700 text-lg dark:text-white mb-2">
                    Become a part of our Discord community and earn 200 Chronos! It{"'"}s easy to claim your reward: just join our Discord server. Once you{"'"}re in, our bot will send you a unique code. Simply copy that code and paste it in the space provided below.
                  </div>
                  <Link href="https://discord.gg/EKa9zwrehc" className="text-blue linkHover font-semibold" target="_blank">Join Now!</Link>
                </div>
                <div className="font-display text-jacarta-700 text-sm font-semibold dark:text-white">
                  Enter Your Code Here: 
                </div>
              </div>

              <div className="dark:border-jacarta-600 border-jacarta-100 relative mb-2 flex items-center overflow-hidden rounded-lg border">
                <input
                  type="text"
                  className="focus:ring-accent h-12 w-full flex-[3] border-0 focus:ring-inse dark:text-jacarta-700"
                  placeholder="Code"
                  value={discordCode}
                  onChange={(e) => handleDiscordCode(e)}
                />
              </div>
            </div>
            {/* <!-- end body --> */}

            <div className="modal-footer">
              <div className="flex items-center justify-center space-x-4">
                <button
                  onClick={() => confimSalvage()}
                  type="button"
                  className="bg-accent shadow-accent-volume hover:bg-accent-dark rounded-full py-3 px-8 text-center font-semibold text-white transition-all"
                >
                  Claim Your Reward!
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default JoinDiscordCodeModal;
