const footerMenuList = [
  {
    id: 1,
    title: "Game",
    diffClass: "md:col-start-7",
    list: [
      {
        id: 2,
        href: "/cards",
        text: "Cards",
      },
      // {
      //   id: 4,
      //   href: "#",
      //   text: "Marketplace",
      // },
      {
        id: 5,
        href: "/ranking",
        text: "Rankings",
      },
      {
        id: 6,
        href: "/activity",
        text: "Activity",
      },
    ],
  },
  {
    id: 2,
    title: "Social",
    diffClass: "",
    list: [
      {
        id: 7,
        href: "https://discord.gg/EKa9zwrehc",
        text: "Discord",
      },
      {
        id: 8,
        href: "https://twitter.com/playchronogram",
        text: "Twitter",
      },
    ],
  }
];

const footerMenuLogedList = [
  {
    id: 1,
    title: "Game",
    diffClass: "md:col-start-7",
    list: [
      {
        id: 2,
        href: "/cards",
        text: "Cards",
      },
      {
        id: 3,
        href: "/dig",
        text: "Dig",
      },
      // {
      //   id: 4,
      //   href: "#",
      //   text: "Marketplace",
      // },
      {
        id: 5,
        href: "/ranking",
        text: "Rankings",
      },
      {
        id: 6,
        href: "/activity",
        text: "Activity",
      },
    ],
  },
  {
    id: 2,
    title: "Social",
    diffClass: "",
    list: [
      {
        id: 7,
        href: "https://discord.gg/EKa9zwrehc",
        text: "Discord",
      },
      {
        id: 8,
        href: "https://twitter.com/playchronogram",
        text: "Twitter",
      },
    ],
  }
];


const socialIcons = [
  {
    id: 1,
    href: "https://discord.gg/EKa9zwrehc",
    text: "discord",
  },
  {
    id: 3,
    href: "https://twitter.com/playchronogram",
    text: "twitter",
  },
];

export { footerMenuList, socialIcons, footerMenuLogedList };
