import { useEffect, useState } from "react";
import Footer from "./footer";
import Wallet_modal from "./modal/wallet_modal";
import BidsModal from "./modal/bidsModal";
import LoginModal from "./modal/loginModal";
import NewcomerModal from "./modal/newcomerModal";
import QuestsModal from "./modal/questsModal";
import InfoPopup from './info-popup/info-popup';
import RegisterModal from "./modal/registerModal";
import ConnectModal from "./modal/connectModal";
import ContinueGuestModal from "./modal/continueGuestModal";
import BuyModal from "./modal/buyModal";
import JoinDiscordCode from "./modal/joinDiscordCode";
import { useRouter } from "next/router";
import Header from "./header/Header";
import Header01 from "./header/Header01";
import Header02 from "./header/Header02";
import Header03 from "./header/Header03";
import Header04 from "./header/Header04";
// import Step2 from "./google-tag-manager/step2"
import { ReactSession } from 'react-client-session';
import { useSelector, useDispatch } from "react-redux";
import { collectToastError, collectToastSuccess, updateTaskCompleted } from "../redux/counterSlice";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function Layout({ children }) {
  const dispatch = useDispatch();
  const { infoPopupData, toastError, toastSuccess, taskCompleted } = useSelector((state) => state.counter);
  const [sendTaskToast, setSendTaskToast] = useState(false);

  useEffect(() => {
    if (toastError) {
      toast.error(toastError, {
        position: "bottom-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "dark",
        onClose: () => dispatch(collectToastError(false)),
      });
    }
    if (toastSuccess) {
      toast.success(toastSuccess, {
        position: "bottom-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "dark",
        onClose: () => dispatch(collectToastSuccess(false)),
      });
    }
  }, [toastError, toastSuccess])

  useEffect(() => {
    if (taskCompleted) {
      setTimeout(() => {
        setSendTaskToast(true)
      }, 1500);
    }
  }, [taskCompleted])

  useEffect(() => {
    if (sendTaskToast) {
      dispatch(updateTaskCompleted(false))
      setSendTaskToast(false)
      toast.success(`Newcomer${"’"}s Challenge: Task completed!`, {
        position: "bottom-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "dark",
        onClose: () => dispatch(collectToastSuccess(false)),
      });
    }
  }, [sendTaskToast])

  const route = useRouter();
  // header start
  let header;
  if (
    route.asPath === "/home/home_3" ||
    route.asPath === "/home/home_9" ||
    route.asPath === "/home/home_12"
  ) {
    header = <Header02 />;
  } else if (route.asPath === "/platform_status") {
    header = <Header03 />;
  } else if (route.asPath === "/home/home_8") {
    header = <Header04 />;
  } else if (route.asPath === "/home/index") {
    header = <Header04 />;
  } else if (route.asPath === "/match") {
    header = null;
  } else {
    header = <Header />;
  }
  // header end
  if (route.asPath === "/dig" || route.asPath === "/match" || route.asPath === "/decks" || route.asPath === "/termtest") {
    return (
      <>
        {header}
        <ToastContainer
          position="bottom-center"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss={false}
          draggable
          pauseOnHover={false}
          theme="dark"
        />
        <Wallet_modal />
        <BidsModal />
        <LoginModal />
        <NewcomerModal />
        <QuestsModal />
        <RegisterModal />
        <ConnectModal />
        <ContinueGuestModal />
        <BuyModal />
        <JoinDiscordCode />
        <main>{children}</main>
        <InfoPopup popupData={infoPopupData} />
      </>
    );
  } else {
    return (
      <>
        {header}
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss={false}
          draggable
          pauseOnHover
          theme="colored"
        />
        <Wallet_modal />
        <BidsModal />
        <LoginModal />
        <NewcomerModal />
        <QuestsModal />
        <RegisterModal />
        <ConnectModal />
        <ContinueGuestModal />
        <BuyModal />
        <JoinDiscordCode />
        <main>{children}</main>
        <Footer />
        <InfoPopup popupData={infoPopupData} />
      </>
    );
  }


}
