import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { newcomerModalHide, collectTasksData, collectToastError, collectToastSuccess, collectUserData } from "../../redux/counterSlice";
import { ReactSession } from 'react-client-session';
import CreateMatchManager from "/components/create-match-manager/create-match-manager.jsx";
import Image from "next/image";

const NewcomerModal = ({ tutorialType }) => {
  const { newcomerModal, tasksData, userData } = useSelector((state) => state.counter);
  const dispatch = useDispatch();
  ReactSession.setStoreType("localStorage");

  // const [user, setUser] = useState([]);
  const [showMatchPopup, setshowMatchPopup] = useState(false);

  useEffect(() => {
    if (ReactSession.get("user")) {
      // setUser(ReactSession.get("user"))
      fetch(`${process.env.NEXT_PUBLIC_API_ROUTE}/api/users/getOnboardingTasks/`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
          "Authorization": `Token ${ReactSession.get("token")}`,
        },
      })
        .then(res => res.json())
        .then(response => {
            if (response.success) {
              dispatch(collectTasksData(response.data))
              ReactSession.set("tasks", response.data)
            }
        });
    }
  }, []);

  useEffect(() => {
    if (tasksData && tasksData.length) {
      for (var taskDataIndex in tasksData) {
        const taskData = tasksData[taskDataIndex];
        if (!taskData.completed && taskData?.task?.identifier) {
          setSelected(taskData.task.identifier)
          break;
        }
      }
    }
  }, [tasksData]);

  const [selected, setSelected] = useState(null);
  const handleAccordion = (id) => {
    if (selected === id) {
      setSelected(null);
    } else {
      setSelected(id);
    }
  };

  const cantCollect = () => {
    dispatch(collectToastError("Complete all the tasks in the list to claim the reward"));
  };

  const collectOnboarding = () => {
    const details = {};
    let formBody = [];
    for (var property in details) {
      var encodedKey = encodeURIComponent(property);
      var encodedValue = encodeURIComponent(details[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");
    fetch(`${process.env.NEXT_PUBLIC_API_ROUTE}/api/users/collectOnboarding/`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
        "Authorization": `Token ${ReactSession.get("token")}`,
      },
      body: formBody
    })
      .then(res => res.json())
      .then(response => {
          if (response.success) {
            const tempUser = ReactSession.get("user");
            tempUser.collectedOnboarding = true;
            tempUser.bombs += 1;
            ReactSession.set("user", tempUser);
            dispatch(collectUserData(tempUser));
            dispatch(collectToastSuccess("Congratulations, your rewards were claimed!"));
            dispatch(newcomerModalHide())
          }
      });
  };

  const canCollectOnboarding = tasksData.filter((item) => item.completed).length === tasksData.length && tasksData.length ? true : false;

  return (
    <div>
      <div className={newcomerModal ? "modal fade show block" : "modal fade"} style={{ alignItems: "normal" }}>
        <div className="modal-dialog max-w-\[90\.375rem\]" style={{ marginTop: "10px" }}>
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="placeBidLabel">
                Newcomers{"'"} Challenge
              </h5>
              <button
                type="button"
                className="btn-close"
                onClick={() => dispatch(newcomerModalHide())}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="24"
                  height="24"
                  className="fill-jacarta-700 h-6 w-6 dark:fill-white"
                >
                  <path fill="none" d="M0 0h24v24H0z"></path>
                  <path d="M12 10.586l4.95-4.95 1.414 1.414-4.95 4.95 4.95 4.95-1.414 1.414-4.95-4.95-4.95 4.95-1.414-1.414 4.95-4.95-4.95-4.95L7.05 5.636z"></path>
                </svg>
              </button>
            </div>

            {/* <!-- Body --> */}
            <div className="mt-5 mb-5">
              <div className="accordion w-95 max-w-[35rem]" id="accordionFAQ">
                <div className="mb-5">
                  <Image
                    width={600}
                    height={200}
                    priority
                    src="/images/events/Newcommers_banner.jpg"
                    alt="gradient"
                    className="h-full w-full object-cover"
                  />
                </div>
                {tasksData.length > 1 && tasksData.map((item) => {
                  const { completed, task } = item;
                  return (
                    <div
                      key={task.identifier}
                      className="accordion-item dark:border-jacarta-600 dark:hover:border-accent border-jacarta-100 overflow-hidden border-1 transition-all"
                    >
                      <h2
                        className="accordion-header relative"
                        id="faq-heading-1"
                        onClick={() => handleAccordion(task.identifier)}
                      >
                        <button
                          className={
                            selected === task.identifier
                              ? 'goButtonBox accordion-button dark:bg-dark font-display text-jacarta-700 relative flex w-full items-center justify-between bg-white px-4 py-3 text-left dark:text-white '
                              : 'goButtonBox accordion-button dark:bg-dark font-display text-jacarta-700 collapsed relative flex w-full items-center justify-between bg-white px-4 py-3 text-left dark:text-white '
                          }
                          type="button"
                        >
                          <div>
                            <svg className={`inline-block vertical-sub icon h-6 w-6 ${completed ? "fill-green" : "fill-jacarta-300"}`} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" style={{ marginBottom: "-2px" }}>
                              <path d="M10.007 2.10377C8.60544 1.65006 7.08181 2.28116 6.41156 3.59306L5.60578 5.17023C5.51004 5.35763 5.35763 5.51004 5.17023 5.60578L3.59306 6.41156C2.28116 7.08181 1.65006 8.60544 2.10377 10.007L2.64923 11.692C2.71404 11.8922 2.71404 12.1078 2.64923 12.308L2.10377 13.993C1.65006 15.3946 2.28116 16.9182 3.59306 17.5885L5.17023 18.3942C5.35763 18.49 5.51004 18.6424 5.60578 18.8298L6.41156 20.407C7.08181 21.7189 8.60544 22.35 10.007 21.8963L11.692 21.3508C11.8922 21.286 12.1078 21.286 12.308 21.3508L13.993 21.8963C15.3946 22.35 16.9182 21.7189 17.5885 20.407L18.3942 18.8298C18.49 18.6424 18.6424 18.49 18.8298 18.3942L20.407 17.5885C21.7189 16.9182 22.35 15.3946 21.8963 13.993L21.3508 12.308C21.286 12.1078 21.286 11.8922 21.3508 11.692L21.8963 10.007C22.35 8.60544 21.7189 7.08181 20.407 6.41156L18.8298 5.60578C18.6424 5.51004 18.49 5.35763 18.3942 5.17023L17.5885 3.59306C16.9182 2.28116 15.3946 1.65006 13.993 2.10377L12.308 2.64923C12.1078 2.71403 11.8922 2.71404 11.692 2.64923L10.007 2.10377ZM6.75977 11.7573L8.17399 10.343L11.0024 13.1715L16.6593 7.51465L18.0735 8.92886L11.0024 15.9999L6.75977 11.7573Z"></path>
                            </svg>
                            <span className="inline-block pl-2">{task.name}</span>
                          </div>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            width="24"
                            height="24"
                            className="accordion-arrow fill-jacarta-700 h-4 w-4 shrink-0 transition-transform dark:fill-white"
                          >
                            <path fill="none" d="M0 0h24v24H0z"></path>
                            <path d="M12 13.172l4.95-4.95 1.414 1.414L12 16 5.636 9.636 7.05 8.222z"></path>
                          </svg>
                        </button>
                        { !completed ? (<>
                          { task.path === "/match" ? (
                            <a
                              href="#"
                              onClick={() => setshowMatchPopup(true)}
                              className="bg-accent shadow-accent-volume hover:bg-accent-dark rounded-full text-center text-white transition-all eventGo"
                            >
                              Go There
                            </a>
                          ) : (
                            <a
                              href={task.path}
                              className="bg-accent shadow-accent-volume hover:bg-accent-dark rounded-full text-center text-white transition-all eventGo"
                            >
                              Go There
                            </a>
                          ) }
                        </>) : null }
                      </h2>
                      <div
                        id="faq-1"
                        className={
                          selected === task.identifier
                            ? 'accordion-collapse collapse show '
                            : 'accordion-collapse collapse'
                        }
                        aria-labelledby="faq-heading-1"
                        data-bs-parent="#accordionFAQ"
                      >
                        <div className="accordion-body dark:bg-darkest dark:border-jacarta-600 border-jacarta-100 border-t bg-white p-4">
                          <p className="dark:text-jacarta-200">{task.description}</p>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
            {/* <!-- end body --> */}

            <div className="modal-footer">
              <span className="mb-3">Complete all the tasks in the Newcomers{"'"} Challenge to claim a Bomb as your reward! You can complete the tasks in any order and at your own pace. </span>
              <div className="flex items-center justify-center space-x-4">
                { canCollectOnboarding && !userData?.collectedOnboarding ? (
                  <button
                    onClick={() => collectOnboarding()}
                    type="button"
                    className="bg-accent shadow-accent-volume hover:bg-accent-dark w-36 rounded-full py-3 px-8 text-center font-semibold text-white transition-all"
                  >
                    Collect
                  </button>
                ) : null }
                { !canCollectOnboarding && !userData?.collectedOnboarding ? (
                  <button
                    onClick={() => cantCollect()}
                    type="button"
                    className="dark:bg-accent dark:shadow-accent-volume dark:hover:bg-accent-dark text-accent  dark:text-white shadow-white-volume w-36 rounded-full bg-white py-3 px-8 text-center font-semibold transition-all"
                  >
                    Collect
                  </button>
                ) : null }
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* START MATCH POPUP */}
      {showMatchPopup ?
        <CreateMatchManager cancelAction={() => setshowMatchPopup(false)} /> :
        null
      }
      {/* END START MATCH POPUP */}
    </div>
  );
};

export default NewcomerModal;
