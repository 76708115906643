import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { signIn } from 'next-auth/react';
import { loginModalHide, registerModalShow, collectToastSuccess } from "../../redux/counterSlice";

const LoginModal = () => {
  const { loginModal } = useSelector((state) => state.counter);
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");
  const [sendLnkText, setSendLnkText] = useState("Sign In");
  const [errrorMessage, setErrrorMessage] = useState("");
  const [disabled, setDisabled] = useState(false);

  const handleEEmail = (e) => {
    e.preventDefault();
    setErrrorMessage("")
    setEmail(e.target.value);
  };

  const handleClose = () => {
    setDisabled(false)
    setEmail("")
    dispatch(loginModalHide())
  };

  const handleRegister = () => {
    handleClose()
    dispatch(registerModalShow())
  };

  function sendLoginLink() {
    const requestBody = {
        email: email
    };
    const regex = /^([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
    if (email && email.length && regex.test(email)) {
      setDisabled(true)
      fetch(`${process.env.NEXT_PUBLIC_API_ROUTE}/api/users/login/`, {
        method: 'POST',
        headers: {
          "content-type": "application/json"
        },
        body: JSON.stringify(requestBody)
      })
        .then(res => res.json())
        .then(response => {
            if (response.success) {
              dispatch(collectToastSuccess("Login link sent! Check your email to access Chronogram"));
              handleClose()
              // setSendLnkText("Successfully Sent!");
              // setTimeout(() => {
              //   dispatch(loginModalHide())
              //   setSendLnkText("Send me a link")
              // }, 3000);
            } else {
              setErrrorMessage("Invalid Email")
              setSendLnkText("Send me a link")
              setDisabled(false)
            }
        });
    }
  }

  return (
    <div>
      <div className={loginModal ? "modal fade show block" : "modal fade"}>
        <div className="modal-dialog max-w-\[90\.375rem\]">
          <div className="modal-content">
            <div className="modal-header loginHeader">
              <h5 className="modal-title" id="placeBidLabel">
                Login to Chronogram
              </h5>
              <button
                type="button"
                className="btn-close"
                onClick={() => handleClose()}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="24"
                  height="24"
                  className="fill-jacarta-700 h-6 w-6 dark:fill-white"
                >
                  <path fill="none" d="M0 0h24v24H0z"></path>
                  <path d="M12 10.586l4.95-4.95 1.414 1.414-4.95 4.95 4.95 4.95-1.414 1.414-4.95-4.95-4.95 4.95-1.414-1.414 4.95-4.95-4.95-4.95L7.05 5.636z"></path>
                </svg>
              </button>
            </div>

            {/* <!-- Body --> */}
            <div className="modal-body p-6">
              <div>
                <input
                  type="email"
                  className="h-12 w-full flex-[3]"
                  placeholder="Enter your email"
                  value={email}
                  onChange={(e) => handleEEmail(e)}
                />
              </div>

              <div className="text-right">
                { errrorMessage ? (
                  <span className="dark:text-jacarta-400 text-sm" style={{ color: "#c92c2c" }}>
                    {errrorMessage}
                  </span>
                ) : null }
              </div>

              {/* <!-- Terms --> */}
              <div className="mt-4 flex items-center space-x-2">
                <label
                  htmlFor="terms"
                  className="dark:text-jacarta-200 text-sm font-semibold"
                >
                  Don&apos;t have an account? {" "}
                  <a href="javascript:void(0)" onClick={() => handleRegister()} className="text-accent">
                     Sign Up
                  </a>
                </label>
              </div>
            </div>
            {/* <!-- end body --> */}

            <div className="modal-footer loginFooter">
              <div className="separation">or</div>
              <div className="flex items-center justify-center space-x-4">
                { disabled ? (
                  <button
                    disabled={disabled}
                    type="button"
                    className="loginButton text-accent shadow-white-volume rounded-full bg-white py-3 px-8 text-center font-semibold transition-all"
                  >
                    {sendLnkText}
                  </button>
                ) : (
                  <button
                    disabled={disabled}
                    onClick={() => sendLoginLink()}
                    type="button"
                    className="loginButton bg-accent shadow-accent-volume hover:bg-accent-dark rounded-full py-3 px-8 text-center font-semibold text-white transition-all"
                  >
                    {sendLnkText}
                  </button>
                ) }
              </div>
            </div>
            <div className="mb-8">
              <div className="flex items-center justify-center space-x-4">
                <button 
                  className="py-3 px-8 text-center font-semibold text-white transition-all bgGoogleLogin loginButton"
                  onClick={() => signIn('google')}>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginModal;
